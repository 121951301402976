import Header from "../Header/header";
import "./NotFound.scss";

const NotFound = () => {
	return (
		<div className="notfound-container">
			<Header></Header>

			<div className="notfound-message">
				<h1>Norlys Partnerportal</h1>
				<div>Ingen partner fundet ud fra den indtastede URL.</div>
			</div>
		</div>
	);
};

export default NotFound;
