import checkMark from "../../../../Assets/Icons/greenCheckCircle.svg";
import { Typography, Box, styled } from "@mui/material";

interface IProps {
	text: string;
}

export const BulletPoint: React.FC<IProps> = (props) => {
	return (
		<BulletContainer>
			<img src={checkMark} alt="Flueben i grøn cirkel" />
			<Typography variant="body3">{props.text}</Typography>
		</BulletContainer>
	);
};

const BulletContainer = styled(Box)`
	display: flex;
	align-items: center;
	max-height: 2rem;
	margin: 0.25rem 0;
	img {
		width: 3.5rem;
		margin-top: 0.625rem;
		margin-right: -0.75rem;
	}
`;
