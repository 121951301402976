import { Box, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DashedLine } from "../../DashedLine/DashedLine";
import {
	getChargingStandEjeInformationText,
	getChargingStandLejeInformationText,
	getFlexElInformationText,
	getKombiElInformationText,
	getMånedsElInformationText,
} from "./PriceDetailTexts";
import { ProductCategory, ProductSubcategory } from "../../../../Models/Enums/ProductCategoryEnum";
import { IDiscounts } from "../ProductCard/ProductCard";
import PartnerContentContext from "../../../../Contexts/PartnerContentContext";

export interface IPriceDetailsProps {
	price?: number;
	unit: string;
	renderInfoText: boolean;
	header?: string;
	subcategory: string;
	category: string;
	discounts: IDiscounts;
	substitution: boolean;
	emobilityInstallationDiscount: number | undefined;
	emobilitySubstitutionDiscount: number | undefined;
	emobilitySubscriptionDiscount: number | undefined;
}

export const PriceDetail: React.FC<IPriceDetailsProps> = (props) => {
	const [isElProduct, setIsElProduct] = useState(true);

	const partnerContentContext = useContext(PartnerContentContext);

	useEffect(() => {
		setIsElProduct(props.category === ProductCategory.El);
	}, [props.category]);

	function handleFetchInformationText(subcategory: string) {
		if (subcategory === ProductSubcategory.FLEX) {
			return getFlexElInformationText();
		} else if (subcategory === ProductSubcategory.PULJE) {
			return getMånedsElInformationText();
		} else if (subcategory === ProductSubcategory.KOMBI) {
			return getKombiElInformationText();
		} else if (subcategory === ProductSubcategory.LEJE) {
			return getChargingStandLejeInformationText();
		} else if (subcategory === ProductSubcategory.EJE) {
			return getChargingStandEjeInformationText();
		}
	}

	const price = useMemo(() => {
		let finalPrice: number = props.price ?? 0;
		if (props.discounts.installationDiscount)
			finalPrice -= props.emobilityInstallationDiscount ?? 0;
		if (props.discounts.substitutionDiscount && props.substitution)
			finalPrice -= props.emobilitySubstitutionDiscount ?? 0;
		return finalPrice;
	}, [props.discounts, props.price, props.substitution]);

	return (
		<ModalContentContainer>
			<Typography variant="h5" sx={{ alignSelf: "center" }}>
				{props.header ?? "Prisdetaljer"}
			</Typography>
			<Box mt={1}>
				<PriceRow>
					<Typography variant="body1">{isElProduct ? "Elpris" : "Laderprisen"}</Typography>
					<Typography variant="body1">
						{props.price?.toLocaleString()} {props.unit}
					</Typography>
				</PriceRow>
				{isElProduct && (
					<PriceRow>
						<Typography variant="body1">Transport og afgifter</Typography>
						<Typography variant="body1">
							{(
								partnerContentContext.transportAndCharges?.amountInKronerIncludingVat ?? 0
							).toLocaleString("da-DK", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
							})}{" "}
							kr./kWh
						</Typography>
					</PriceRow>
				)}
				{props.discounts.installationDiscount && props.emobilityInstallationDiscount && (
					<PriceRow>
						<Typography variant="body1">Installationsrabat</Typography>
						<Typography variant="body1">-{props.emobilityInstallationDiscount} kr.</Typography>
					</PriceRow>
				)}
				{props.substitution &&
					props.discounts.substitutionDiscount &&
					props.emobilitySubstitutionDiscount && (
						<PriceRow>
							<Typography variant="body1">Udskiftningsrabat</Typography>
							<Typography variant="body1">-{props.emobilitySubstitutionDiscount} kr.</Typography>
						</PriceRow>
					)}
				<PriceRow>
					<Typography variant="body1bold">Samlet pris</Typography>
					{isElProduct ? (
						<Typography variant="body1bold">
							{(
								((props.price ?? 0) +
									(partnerContentContext.transportAndCharges?.amountInKronerIncludingVat ?? 0) *
										100) /
								100
							).toLocaleString("da-DK", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
							})}{" "}
							kr./kWh
						</Typography>
					) : (
						<Typography variant="body1bold">{price.toLocaleString()} kr.</Typography>
					)}
				</PriceRow>
				<PriceRow>
					<Typography variant="body1">Abonnement</Typography>
					<Typography variant="body1">
						{isElProduct ? "29" : props.subcategory === ProductSubcategory.EJE ? "79" : "199"}{" "}
						kr./md
					</Typography>
				</PriceRow>
			</Box>
			{props.renderInfoText && (
				<>
					<DashedLine marginTop="16" />
					<InformationTextContainer>
						{handleFetchInformationText(props.subcategory)}
					</InformationTextContainer>
				</>
			)}
		</ModalContentContainer>
	);
};

const ModalContentContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 2rem 4rem;

	@media (max-width: 768px) {
		margin: 2rem 0.5rem;
	}
`;

const PriceRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
`;

const InformationTextContainer = styled(Box)`
	margin-top: 1rem;
`;
