import axios from "axios";
import { APIUrl } from "../constants";
import { IPartnerProduct } from "../Models/PartnerProductModel";
import { ITransportAndCharges } from "../Models/TransportAndCharges";

const api = axios.create({
	baseURL: `${APIUrl}`,
	//baseURL: `https://localhost:44309`,
	//baseURL: `https://localhost:5001`,
});

export const ProductService = {
	getProducts: async (productIds: Array<any>): Promise<Array<IPartnerProduct>> => {
		const res = await api.post<Array<IPartnerProduct>>(
			`/api/v1/PartnerProduct/get-assigned-products`,
			productIds
		);

		return res.data;
	},
	getTransportAndCharges: async (productId: string): Promise<ITransportAndCharges> => {
		const res = await api.get<ITransportAndCharges>(
			`/api/v1/Product/get-transport-and-charges-by-id/${productId}`
		);

		return res.data;
	},
};
