import {
	Accordion,
	AccordionMinimumHeight,
	AddressTypeahead,
	ButtonColorEnum,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import { IValidationResult } from "norlys-ui-components/lib/components/address-typeahead/Address-Typeahead";
import "./AddressStep.scss";
import Column from "../../../../Utils/Column/Column";
import { useContext, useState } from "react";
import { DAWAToDatahubAddress } from "../../../../../Models/AddressModel";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import img from "../../../../../Assets/Images/Elektricitet-MedVind_colour.png";
import { MobileWrapper } from "../../../../Utils/MobileMargin/MobileMargin";
import { getAddressHasOrderMatch } from "../../../../../Services/ApiService";

const AddressStep = ({
	nextStep,
	order,
	setOrder,
	setAddress,
	open,
	toggleOpen,
	setErrorMode,
}: IStepInterface) => {
	const [addressValid, setAddressValid] = useState(false);
	const partnerContentContext = useContext(PartnerContentContext);
	const [keyboardPadding, setKeyboardPadding] = useState<number>(0);
	const DOMPurify = require("dompurify")(window);

	const handleAddressChange = (val: IValidationResult): any => {
		if (val.valid) {
			let datahubAddress = DAWAToDatahubAddress(val);
			setAddress(datahubAddress);
			setOrder({
				...order,
				deliveryAddress: val.value.tekst,
				deliveryAddressId: val.value.adresse.id.toString(),
				addressRequest: {
					city: val.value.adresse.postnrnavn,
					door: val.value.adresse.dør,
					floor: val.value.adresse.etage,
					houseNumber: val.value.adresse.husnr,
					postCode: val.value.adresse.postnr,
					street: val.value.adresse.vejnavn,
				},
			});
			getAddressHasOrderMatch(val.value.adresse.id.toString()).then((result) => {
				if (result.isSuccessful && result.data) {
					setErrorMode("ExistingCostumer");
					return false;
				}
			});
		} else {
			setOrder({ ...order, deliveryAddress: "", deliveryAddressId: "" });
		}
	};

	const validAddress = (isValid: boolean): boolean => {
		setAddressValid(isValid);
		if (isValid) {
			nextStep();
		}
		return isValid;
	};

	const formValid = () => {
		return addressValid;
	};

	const handleTouchStart = () => {
		setKeyboardPadding(55);
	};

	return (
		<div>
			<div className="address-step-desktop">
				<Accordion
					label={partnerContentContext.addressStep.title}
					open={open}
					click={toggleOpen}
					secondButtonType={formValid() ? ButtonColorEnum.DARK_GREEN : ButtonColorEnum.GREY}
					secondButtonDisabled={!formValid()}
					minimumHeight={AccordionMinimumHeight.EXTRASMALL}>
					<Column>
						<AddressTypeahead
							fuzzy={true}
							validAddress={validAddress}
							placeholder={
								partnerContentContext.addressStep.placeHolder
									? partnerContentContext.addressStep.placeHolder
									: "Leveringsadresse"
							}
							change={handleAddressChange}
						/>
					</Column>
				</Accordion>
			</div>
			<div className="address-step-mobile">
				<div className="address-mobile-container">
					<h1>{partnerContentContext.heroBanner.title}</h1>
					<div>
						<p className="banner-text">
							<b>{partnerContentContext.heroBanner.boldText}</b>
						</p>
						<div
							className="address-text-section"
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(partnerContentContext.heroBanner.text),
							}}></div>
					</div>
					<Column>
						<AddressTypeahead
							touchStart={handleTouchStart}
							fuzzy={true}
							validAddress={validAddress}
							placeholder={
								partnerContentContext.addressStep.placeHolder
									? partnerContentContext.addressStep.placeHolder
									: "Leveringsadresse"
							}
							change={handleAddressChange}
						/>
					</Column>

					<img
						alt="medvind"
						src={
							partnerContentContext.heroBanner.image ? partnerContentContext.heroBanner.image : img
						}></img>
					<MobileWrapper marginTopViewheight={keyboardPadding} />
				</div>
			</div>
		</div>
	);
};

export default AddressStep;
