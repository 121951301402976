class OverlayModel {
	public overlayActive: boolean;
	public overlayMessage: string;
	public overlayLoader: boolean;

	constructor(overlayActive: boolean, overlayMessage: string, overlayLoader: boolean) {
		this.overlayActive = overlayActive;
		this.overlayMessage = overlayMessage;
		this.overlayLoader = overlayLoader;
	}
}

export default OverlayModel;
