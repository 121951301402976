import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonColorEnum, InputComponent, Modal, ValidationTypeEnum } from "norlys-ui-components";
import PartnerContentContext from "../../../Contexts/PartnerContentContext";
import OrderModel from "../../../Models/OrderModel";
import AddressModel from "../../../Models/AddressModel";
import { StepEnum } from "../../../Models/StepEnum";
import "./CallMeUpComponent.scss";
import CallMeUpModel from "../../../Models/CallMeUpModel";
import { postCallMeUpLead } from "../../../Services/ApiService";

interface ICallMeUpComponentInterface {
  order: OrderModel;
  address: AddressModel;
  showSecondButton: boolean;
  idqError: boolean;
  step: StepEnum;
}

const CallMeUpComponent = ({ order, address, showSecondButton, idqError, step }: ICallMeUpComponentInterface) => {
  const partnerContentContext = useContext(PartnerContentContext);
  const [fullName, setFullName] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [submitionSuccessfull, setSubmitionSuccessfull] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const submitFormToHerobase = async () => {
    let res = await postCallMeUpLead(createCmuModel());
    if (res.isSuccessful) {
      setSubmitionSuccessfull(true);
      if (idqError) {
        window.location.replace(`${partnerContentContext.partnerId}/ring-mig-op-success`);
      } else {
        window.location.replace(`ring-mig-op-success`);
      }
    } else {
      setErrorMessage(true);
    }
  };

  const createCmuModel = () => {
    let cmuModel = new CallMeUpModel();
    cmuModel.customerName = fullName;
    cmuModel.customerPhone = phone;
    cmuModel.customerAddress = addressValue;
    cmuModel.zipCode = address.postnr?.postnr ? address.postnr.postnr.toString() : "";
    cmuModel.city = address.supplerendebynavn ? address.supplerendebynavn.toString() : "";
    cmuModel.partnerName = partnerContentContext.partnerId;
    cmuModel.partnerGuid = partnerContentContext.id;
    cmuModel.errorOnStep = step ? getStepFromEnum(step) : getStepFromEnum(0);
    return cmuModel;
  };

  const getStepFromEnum = (step: StepEnum) => {
    switch (step) {
      case 0:
        return "Addresse step";
      case 1:
        return "Produkt step";
      case 2:
        return "Stamoplysninger step";
      case 3:
        return "Flytte step";
      case 4:
        return "Samlever step";
      case 5:
        return "Kontaktoplysninger step";
      case 6:
        return "Opsummering step";
    }
    return "";
  };

  const handleRouting = () => {
    window.history.back();
    window.location.replace(`/${partnerContentContext.partnerId}`);
  };

  useEffect(() => {
    setFullName(order.fullName ? order.fullName : "");
    setAddressValue(order.deliveryAddress ? order.deliveryAddress : "");
    setPhone(order.phone ? order.phone : "");
  }, [order]);

  useEffect(() => {
    if (fullName !== "" && addressValue !== "" && phone.length === 8) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [fullName, addressValue, phone]);
  return (
    <div className="inner-container call-me-up-container">
      <h2>{partnerContentContext.callMeUp?.title ? partnerContentContext.callMeUp?.title : ""}</h2>
      <p>{partnerContentContext.callMeUp?.text ? partnerContentContext.callMeUp?.text : ""}</p>
      <div className="input">
        <InputComponent
          value={fullName}
          placeholder={"Fulde navn"}
          validationType={ValidationTypeEnum.NAME}
          customNameValidationError="Skriv dit fulde navn"
          change={(val): any => setFullName(val.value)}
        />
      </div>
      <div className="input">
        <InputComponent
          value={addressValue}
          placeholder={"Leveringsadresse"}
          validationType={ValidationTypeEnum.TEXT}
          customTextValidationError="Skriv din adresse"
          change={(val): any => setAddressValue(val.value)}
        />
      </div>
      <InputComponent
        value={phone}
        placeholder={"Telefonnummer"}
        validationType={ValidationTypeEnum.PHONENUMBER}
        customPhoneNumberValidationError="Det oplyste telefonnummer er ikke gyldigt"
        change={(val): any => setPhone(val.value)}
      />
      {/* prettier-ignore */}
      <div className="privacy-policy">
					Du kan læse hvordan vi behandler dine data i vores {" "}
					<a href="https://norlys.dk/persondatapolitik" target="blank">
					persondatapolitik
					</a>
			</div>
      {errorMessage && <div className="errorMessage">Der skete desværre en fejl... Prøv igen</div>}
      <div className={idqError ? "idqButtons" : "buttons"}>
        <div>
          <Button
            label={partnerContentContext.callMeUp?.buttonText ?? ""}
            color={disabled ? ButtonColorEnum.GREY : ButtonColorEnum.DARK_GREEN}
            disabled={disabled || submitionSuccessfull}
            onClick={() => submitFormToHerobase()}
          />
        </div>
        {showSecondButton && (
          <div>
            <Button label="Afslut" outline={true} color={ButtonColorEnum.GREY} onClick={() => handleRouting()} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CallMeUpComponent;
