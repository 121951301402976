import { Loader, LoaderSizeEnum } from "norlys-ui-components";
import "./overlay.scss";

interface IOverlay {
	active?: boolean;
	message?: string;
	showLoader?: boolean;
}

const Overlay = ({ active, message, showLoader }: IOverlay) => {
	return (
		<div id="overlayContainer" className={active ? "" : "hidden-element"}>
			{showLoader === true && <Loader size={LoaderSizeEnum.LARGE}></Loader>}
			<div id="overlayMessage">{message}</div>
		</div>
	);
};

export default Overlay;
