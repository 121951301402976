import { useContext, useEffect, useState } from "react";
import PartnerContentContext from "../../../Contexts/PartnerContentContext";
import Overlay from "../../LandingpageComponents/Wizard/Overlay/overlay";
import OverlayModel from "../../../Models/OverlayModel";
import InputStep from "../InputStep/InputStep";
import RedeemStep from "../RedeemStep/RedeemStep";
import VoucherModel from "../../../Models/VoucherModel";
import { getVoucherStatusObject, postUseVoucher } from "../../../Services/ApiService";

import "./Redeempage.scss";
import { Redirect } from "react-router-dom";

const Redeempage = ({
	match: {
		//@ts-ignore
		params: { partnerGuid },
	},
}) => {
	const [voucherObject, setVoucherObject] = useState(new VoucherModel());
	const [showSecondAccordion, setShowSecondAccordion] = useState(false);
	const [noOfRender, setNoOfRender] = useState(0);
	const [inputStepOpenState, setInputStepOpenState] = useState(true);
	const [redeemStepOpenState, setRedeemStepOpenState] = useState(false);

	const partnerContentContext = useContext(PartnerContentContext);
	const overlayObject = new OverlayModel(false, "", false);
	const [isLoading, setIsLoading] = useState(false);

	const checkVoucherValidation = (input: string) => {
		setIsLoading(true);
		getVoucherStatusObject(partnerContentContext.id, input).then((res) => {
			if (res.statusId !== "") {
				if (checkIfExpired(res)) {
					res.statusId = "Udløbet";
				}
				res.isValid = true;
				setVoucherObject(res);
			} else {
				setVoucherObject(res);
			}
			setIsLoading(false);
		});
	};

	const checkIfExpired = (res: VoucherModel) => {
		let now = new Date();
		let issueDate = new Date(res.issueDate);
		let expirationDate = new Date(issueDate.setMonth(issueDate.getMonth() + res.expirationMonths));
		if (expirationDate < now) return true;
		else return false;
	};

	const redeemStepButtonMethod = (shopId: string, sellerId: string) => {
		setIsLoading(true);
		if (voucherObject.statusId === "Udstedt") {
			redeemCode(voucherObject.code, shopId, sellerId);
		} else {
			toggleOpenStates();
			setIsLoading(false);
		}
	};

	const toggleOpenStates = () => {
		setInputStepOpenState(!inputStepOpenState);
		setRedeemStepOpenState(!redeemStepOpenState);
	};

	const redeemCode = async (code: string, shopId: string, sellerId: string) => {
		let res = await postUseVoucher(voucherObject.id.toString(), shopId, sellerId);

		if (res.isSuccessful) {
			checkVoucherValidation(code);
			toggleOpenStates();
		} else {
			<Redirect push to={`/${partnerContentContext.partnerId}/error`}></Redirect>;
		}
	};

	useEffect(() => {
		if (noOfRender > 0) {
			setShowSecondAccordion(true);
			toggleOpenStates();
		}
		setNoOfRender(+1);
	}, [voucherObject]);

	return (
		<div>
			<Overlay
				active={overlayObject.overlayActive}
				message={overlayObject.overlayMessage}
				showLoader={overlayObject.overlayLoader}></Overlay>
			<div className={`step-container`}>
				<div className="title-section">Validering af Norlys rabatkode</div>
				<div>
					<InputStep
						checkVoucherValidation={checkVoucherValidation}
						toggleOpenState={toggleOpenStates}
						open={inputStepOpenState}
						loading={isLoading}
					/>
				</div>
				{showSecondAccordion && (
					<div style={{ marginTop: 30 }}>
						<RedeemStep
							voucherObject={voucherObject}
							redeemStepButtonMethod={(shopId: string, sellerId: string) =>
								redeemStepButtonMethod(shopId, sellerId)
							}
							toggleOpenState={toggleOpenStates}
							open={redeemStepOpenState}
							loading={isLoading}></RedeemStep>
					</div>
				)}
			</div>
		</div>
	);
};

export default Redeempage;
