import {
	Email,
	Accordion,
	AccordionMinimumHeight,
	ButtonColorEnum,
	InputComponent,
	InputTypeEnum,
	ValidationTypeEnum,
	Checkbox,
	AddressTypeahead,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import { IInputValidationResult } from "norlys-ui-components/lib/components/input-component/Input-Component";
import { IValidationResult } from "norlys-ui-components/lib/components/address-typeahead/Address-Typeahead";
import "./ContactInfoStep.scss";
import Column from "../../../../Utils/Column/Column";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { useContext, useEffect, useState } from "react";
import { MobileWrapper } from "../../../../Utils/MobileMargin/MobileMargin";
import { Box, styled } from "@mui/material";

const ContactInfoStep = ({
	nextStep,
	previousStep,
	order,
	open,
	toggleOpen,
	setOrder,
}: IStepInterface) => {
	const partnerContentContext = useContext(PartnerContentContext);
	const [showBillingAddress, setShowBillingAddress] = useState(false);

	useEffect(() => {
		if (!showBillingAddress) {
			order.billingAddress = "";
		}
	}, [showBillingAddress]);

	const handleEmailChange = (val: IInputValidationResult): any => {
		setOrder({ ...order, email: val.valid ? val.value : "" });
	};

	const handlePhonenumberChange = (val: IInputValidationResult): any => {
		setOrder({ ...order, phone: val.valid ? val.value : "" });
	};

	const formValid = () => {
		return (
			order.phone &&
			order.email &&
			((showBillingAddress && order.billingAddress) || !showBillingAddress)
		);
	};

	const handleAddressChange = (val: IValidationResult): any => {
		if (val.valid) {
			setOrder({
				...order,
				billingAddress: val.value.tekst,
				billingAddressId: val.value.adresse.id.toString(),
			});
		} else {
			setOrder({ ...order, billingAddress: "", billingAddressId: "" });
		}
	};

	const validAddress = (isValid: boolean): boolean => {
		if (isValid && formValid()) {
			nextStep();
		}
		return isValid;
	};

	return (
		<Accordion
			label={partnerContentContext.contactInfoStep.title}
			open={open}
			click={toggleOpen}
			firstButtonLabel={
				partnerContentContext.contactInfoStep.backButton
					? partnerContentContext.contactInfoStep.backButton
					: "Tilbage"
			}
			firstButtonOutline={true}
			firstButton={previousStep}
			secondButtonLabel={
				partnerContentContext.contactInfoStep.nextButton
					? partnerContentContext.contactInfoStep.nextButton
					: "Næste"
			}
			secondButtonDisabled={!formValid()}
			secondButtonType={formValid() ? ButtonColorEnum.DARK_GREEN : ButtonColorEnum.GREY}
			secondButton={nextStep}
			minimumHeight={AccordionMinimumHeight.SMALL}
			firstButtonId="ContactInfoStepReturn"
			secondButtonId="ContactInfoStepForward"
			secondButtonShadow={true}>
			<div className="contactInfoContainer">
				<Column>
					<Email change={handleEmailChange} />
				</Column>
				<Column fullWidthMobile={true} noTooltip={true}>
					<FlexContainer>
						<InputComponent
							inputType={InputTypeEnum.PHONENUMBER}
							placeholder={
								partnerContentContext.contactInfoStep.phonePlaceholder
									? partnerContentContext.contactInfoStep.phonePlaceholder
									: "Telefonnummer"
							}
							validationType={ValidationTypeEnum.PHONENUMBER}
							change={handlePhonenumberChange}
						/>
						{partnerContentContext.digitalLeadEnabled && (
							<Box sx={{ fontWeight: "bold", marginTop: "1rem" }}>
								<Checkbox
									change={(): any => {
										setOrder({ ...order, digitalLead: !order.digitalLead });
									}}
									label={"Jeg vil gerne ringes op af Norlys med et tilbud om internet og TV"}
								/>
							</Box>
						)}
					</FlexContainer>
				</Column>
				<Column>
					<FlexContainer>
						<Checkbox
							change={(): any => {
								setShowBillingAddress(!showBillingAddress);
							}}
							label={partnerContentContext.contactInfoStep.billingAddressLabel}
						/>
					</FlexContainer>
				</Column>
				{showBillingAddress && (
					<Column>
						<AddressTypeahead
							fuzzy={true}
							validAddress={validAddress}
							placeholder="Faktureringsadresse"
							change={handleAddressChange}
						/>
					</Column>
				)}
				<MobileWrapper marginTopViewheight={20}></MobileWrapper>
			</div>
		</Accordion>
	);
};

export default ContactInfoStep;

const FlexContainer = styled(Box)`
	display: flex;
	flex-direction: column;

	> div {
		margin-top: -0.2rem;
		margin-bottom: 1rem;
	}

	.nl-checkbox-label {
		margin-top: 0.5rem;
	}

	@media screen and (max-width: 768px) {
		align-items: start;

		> div {
			margin-top: -0.8rem;
			margin-bottom: 1rem;
			width: 100%;
		}

		.nl-checkbox-label {
			margin-top: -0.2rem;
		}
	}
`;
