import { Box, Button, Typography, styled } from "@mui/material";
import { BulletPoint } from "../BulletPoint/BulletPoint";
import { IPartnerProduct } from "../../../../Models/PartnerProductModel";
import { Colors } from "../../../../Themes/colors";
import { ProductCategory } from "../../../../Models/Enums/ProductCategoryEnum";
import { IPriceDetailsProps } from "../PriceDetails/PriceDetail";
import { useMemo } from "react";

export interface IDiscounts {
	installationDiscount: boolean;
	substitutionDiscount: boolean;
	subscriptionDiscount: boolean;
}

interface IProps {
	product: IPartnerProduct;
	chosen: boolean;
	useMainPrice?: boolean;
	setChosenOrderCardKey: React.Dispatch<React.SetStateAction<string>>;
	onButtonClick: (priceDetailProps: IPriceDetailsProps) => void;
	discounts: IDiscounts;
	substitution: boolean;
}

export const ProductCard: React.FC<IProps> = (props) => {
	function handleCardClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const clickedElement = event.target as HTMLElement;
		if (!clickedElement || !clickedElement.closest("button")) {
			props.setChosenOrderCardKey(props.chosen ? "" : props.product.id);
		}
	}

	const price = useMemo(() => {
		let finalPrice: number = props.product.mainPrice ?? 0;
		if (props.discounts.installationDiscount)
			finalPrice -= props.product.emobilityInstallationDiscount ?? 0;
		if (props.discounts.substitutionDiscount && props.substitution)
			finalPrice -= props.product.emobilitySubstitutionDiscount ?? 0;
		return finalPrice;
	}, [props.discounts, props.product.mainPrice, props.substitution]);

	return (
		<BorderContainer sx={{ marginTop: props.chosen ? "0" : "3rem" }}>
			{props.chosen && (
				<ChosenFlag>
					<Typography variant="body1" mt={1} color={"white"}>
						Dit valgte produkt
					</Typography>
				</ChosenFlag>
			)}
			<CardContainer onClick={(event) => handleCardClick(event)}>
				<TitleContainer>
					<Typography variant="h2" sx={{ textWrap: "nowrap" }}>
						{props.product.title}
					</Typography>
					<SubtitleContainer mt={2}>
						<Typography variant="body1">{props.product.subtitle}</Typography>
					</SubtitleContainer>
				</TitleContainer>
				<PriceContainer>
					{ProductCategory[props.product.category] === ProductCategory.Emobility && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								minHeight: "3.25rem",
							}}>
							<Typography variant="body1">Kom i gang fra </Typography>
							{((props.discounts.installationDiscount &&
								props.product.emobilityInstallationDiscount) ||
								(props.discounts.substitutionDiscount &&
									props.product.emobilitySubstitutionDiscount)) && (
								<Typography
									sx={{
										textDecoration: "line-through",
										textDecorationThickness: "0.15rem",
									}}
									variant="body1">
									{props.product.mainPrice?.toLocaleString("da-DK")} kr.
								</Typography>
							)}
						</Box>
					)}
					<Box sx={{ display: "flex", alignItems: "baseline" }} mb={1}>
						{ProductCategory[props.product.category] === ProductCategory.El ? (
							<Typography variant="h1">
								{props.useMainPrice
									? props.product.mainPrice?.toLocaleString("da-DK", {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
									  })
									: props.product.secondaryPrice?.toLocaleString("da-DK", {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
									  })}
							</Typography>
						) : (
							<Typography variant="h1">{price.toLocaleString("da-DK")}</Typography>
						)}
						<Typography variant="body1" mt={2.5} ml={0.5}>
							{props.product.unit}
						</Typography>
					</Box>
					<Box sx={{ minHeight: "2.5rem" }}>
						<Button
							variant="norlys"
							color="secondary"
							onClick={() =>
								props.onButtonClick({
									price:
										ProductCategory[props.product.category] === ProductCategory.Emobility
											? props.product.mainPrice
											: props.useMainPrice
											? props.product.mainPrice
											: props.product.secondaryPrice,
									unit: props.product.unit,
									renderInfoText: true,
									subcategory: props.product.subcategory,
									category: props.product.category,
									discounts: props.discounts,
									substitution: props.substitution,
									emobilityInstallationDiscount: props.product.emobilityInstallationDiscount,
									emobilitySubstitutionDiscount: props.product.emobilitySubstitutionDiscount,
									emobilitySubscriptionDiscount: props.product.emobilitySubscriptionDiscount,
								})
							}
							disableRipple>
							Se prisen i detaljer
						</Button>
					</Box>
				</PriceContainer>
				<BulletPointContainer>
					{props.product.bulletPoints.map((bulletText, index) => {
						if (bulletText ? bulletText.length > 0 : false) {
							return <BulletPoint text={bulletText} key={index} />;
						}
					})}
				</BulletPointContainer>
				<Box sx={{ textAlign: "center", margin: "-0.75rem 0 1rem", minHeight: "1.5rem" }}>
					<Typography variant="body3">{props.product.conditionalNote ?? ""}</Typography>
				</Box>
			</CardContainer>
		</BorderContainer>
	);
};

const BorderContainer = styled(Box)`
	max-width: 19rem;
	min-width: 18rem;
	box-shadow: 0rem 0.5rem 1.5rem 0rem #0000001f;
	background-color: ${Colors.greenDark};
	border-radius: 1rem;
	padding: 0.313rem;
	height: fit-content;
	margin: 0 1.5rem;

	@media (max-width: 768px) {
		margin-top: 1rem;
		min-width: 19rem;
	}
`;

const CardContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	background-color: ${Colors.white};
	flex-grow: 1;
	min-height: 34.875rem;

	&:hover {
		cursor: pointer;
	}
`;

const TitleContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 2rem 1.5rem;
	text-align: center;
	min-height: 9rem;
`;

const SubtitleContainer = styled(Box)`
	display: flex;
	align-items: center;
	max-height: 5rem;
	min-height: 5rem;
`;

const PriceContainer = styled(Box)`
	flex: 1;
	background-color: ${Colors.greenBright};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1.25rem 0 1.75rem;
	max-height: 8rem;
`;

const BulletPointContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 1.5rem 0.5rem 1.5rem;
	min-height: 7.5rem;
	max-height: 7.5rem;
`;

const ChosenFlag = styled(Box)`
	width: 100%;
	background-color: ${Colors.greenDark};
	border-radius: 1rem;
	min-height: 2.5rem;
	text-align: center;
`;
