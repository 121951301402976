import { useState } from "react";
import {
  Accordion,
  AccordionMinimumHeight,
  ButtonColorEnum,
  InputComponent,
  ValidationTypeEnum,
} from "norlys-ui-components";
import Column from "../../Utils/Column/Column";
import VoucherModel from "../../../Models/VoucherModel";
import { DashedLine } from "../../Utils/DashedLine/DashedLine";

interface IRedeemStepInterface {
  voucherObject: VoucherModel;
  redeemStepButtonMethod(shopId: string, sellerId: string): void;
  toggleOpenState(): void;
  open: boolean;
  loading: boolean;
}

const RedeemStep = ({
  voucherObject,
  redeemStepButtonMethod,
  toggleOpenState,
  open,
  loading,
}: IRedeemStepInterface) => {
  const [shopId, setShopId] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [sellerIdError, setSellerIdError] = useState("");
  const [isValidSellerId, setIsValidSellerId] = useState(false);
  const [isValidShopId, setIsValidShopId] = useState(false);

  const setLabelText = () => {
    if (!voucherObject.isValid) {
      return "Rabatkoden findes ikke...";
    } else if (voucherObject.statusId === "Udstedt") {
      return "Rabatkoden er gyldig";
    } else if (voucherObject.statusId === "Brugt") {
      return "Rabatkoden er indløst";
    } else {
      return `Rabatkoden er ${voucherObject.statusId.toLowerCase()}`;
    }
  };

  const setSecondButtonLabel = () => {
    if (voucherObject.statusId === "Udstedt") {
      return "INDLØS KODE";
    } else {
      return "TILBAGE";
    }
  };

  const setSecondButtonType = () => {
    if (voucherObject.statusId === "Udstedt") {
      return ButtonColorEnum.DARK_GREEN;
    } else {
      return ButtonColorEnum.GREY;
    }
  };

  const setSecondButtonOutline = () => {
    if (voucherObject.statusId === "Udstedt") {
      return false;
    } else {
      return true;
    }
  };

  const getFormattedDate = (inputDate: string) => {
    let date = new Date(inputDate);
    return `${date.getDate()}/${date.getMonth() + 1} - ${date.getFullYear()}`;
  };

  const getExpirationDate = () => {
    let issueDate = new Date(voucherObject.issueDate);
    return new Date(issueDate.setMonth(issueDate.getMonth() + voucherObject.expirationMonths));
  };

  return (
    <div>
      <Accordion
        loading={loading}
        loadingText="Indløser rabatkode"
        loadingTitle="Vent venligst"
        label={setLabelText()}
        open={open}
        click={toggleOpenState}
        secondButtonLabel={setSecondButtonLabel()}
        secondButtonDisabled={voucherObject.statusId === "Udstedt" && !(isValidShopId && isValidSellerId)}
        secondButtonType={setSecondButtonType()}
        secondButton={() => redeemStepButtonMethod(shopId, sellerId)}
        secondButtonOutline={setSecondButtonOutline()}
        minimumHeight={AccordionMinimumHeight.EXTRASMALL}
        secondButtonId="redeemStepButtonMethod"
      >
        {!voucherObject.isValid && (
          <Column fullWidth={true}>
            <p>Den indtastede rabatkode er muligvis forkert, tjek rabatkoden efter og prøv igen.</p>
          </Column>
        )}

        {voucherObject.isValid && (
          <>
            <Column fullWidth={true}>
              <p>Koden er udstedt d. {getFormattedDate(voucherObject.issueDate)} til</p>
              <p style={{ textAlign: "end", fontWeight: "bold" }}>
                {voucherObject.customerName ?? "Navnet er undefined"}
              </p>
            </Column>
            <DashedLine />
            <Column fullWidth={true}>
              <p style={{ marginTop: 10 }}>Status for rabatkode</p>
              {voucherObject.statusId === "Brugt" && (
                <p
                  style={{
                    color: "red",
                    textAlign: "end",
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                >
                  INDLØST d. {getFormattedDate(voucherObject.statusChangeDate)}
                </p>
              )}
              {voucherObject.statusId === "Udstedt" && (
                <p
                  style={{
                    color: "green",
                    textAlign: "end",
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                >
                  IKKE INDLØST
                </p>
              )}
              {(voucherObject.statusId === "Annulleret" || voucherObject.statusId === "Udløbet") && (
                <p
                  style={{
                    color: "red",
                    textAlign: "end",
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                >
                  {voucherObject.statusId.toUpperCase()} d.{" "}
                  {voucherObject.statusId === "Udløbet"
                    ? getFormattedDate(getExpirationDate().toString())
                    : getFormattedDate(voucherObject.statusChangeDate)}
                </p>
              )}
            </Column>
            <DashedLine />
            {voucherObject.statusId === "Udstedt" && (
              <div style={{ marginTop: 10 }}>
                <Column>
                  <div>
                    <InputComponent
                      placeholder="Sælger ID"
                      validationType={ValidationTypeEnum.NOVALIDATION}
                      customTextValidationError="Må kun indholde bogstaver"
                      customValidator={(e: string) => {
                        let valid = /^(\D{1,30})$/.test(e);
                        setIsValidSellerId(valid);
                        setSellerIdError(valid ? "" : "Må kun indholde bogstaver");
                        return valid;
                      }}
                      change={(e): any => setSellerId(e.value)}
                      value={voucherObject.sellerId ? voucherObject.sellerId : ""}
                    />
                    <div className="error-section" style={{ marginTop: "15px" }}>
                      {sellerIdError}
                    </div>
                  </div>

                  <InputComponent
                    placeholder="Butiks ID"
                    validationType={ValidationTypeEnum.NUMBER}
                    change={(e): any => {
                      setShopId(e.value);
                      setIsValidShopId(e.valid);
                    }}
                    value={voucherObject.shopId ? voucherObject.shopId : ""}
                  />
                </Column>
                <Column fullWidth={true}>
                  <div>
                    Husk at kontrollere at kundens ID og navnet på ordren stemmer overens, før du indløser koden
                  </div>
                </Column>
              </div>
            )}
            {voucherObject.statusId === "Brugt" && (
              <div style={{ marginTop: 10 }}>
                <Column>
                  <p>Indløst af </p>
                  <p style={{ textAlign: "end", fontWeight: "bold" }}>
                    Butiks id: {voucherObject.shopId} - Sælger id: {voucherObject.sellerId}
                  </p>
                </Column>
              </div>
            )}
          </>
        )}
      </Accordion>
    </div>
  );
};

export default RedeemStep;
