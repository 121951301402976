import { createGlobalStyle } from "styled-components";
import NorlysHeadlineBold from "./NORLYSHeadline-Bold.woff";
import NorlysTextSemiBold from "./NORLYSText-SemiBold.woff";
import NorlysTextBold from "./NORLYSText-Bold.woff";
import NorlysTextRegular from "./NORLYSText-Regular.woff";

const FontStyles = createGlobalStyle`
@font-face {
	font-family: "NORLYSHeadline-Bold";
	src: local("NORLYSHeadline-Bold"), url(${NorlysHeadlineBold}) format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "NORLYSText-SemiBold";
	src: local("NORLYSText-SemiBold"), url(${NorlysTextSemiBold}) format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "NORLYSText-Bold";
	src: local("NORLYSText-Bold"), url(${NorlysTextBold}) format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "NORLYSText-Regular";
	src: local("NORLYSText-Regular"), url(${NorlysTextRegular}) format("woff");
	font-weight: 400;
	font-style: normal;
}
`;

export default FontStyles;
