import { Box, Typography } from "@mui/material";

export function getFlexElInformationText() {
	return (
		<Box>
			<Typography variant="body2" mt={-1}>
				Den viste elpris for FlexEl er sidste måneds gennemsnitspris, og vil derfor være forskellig
				fra din kommende faktiske timepris, når vi begynder at levere el til dig.
			</Typography>
			<Box mt={2}>
				<Typography variant="body1bold">Bliv klogere på FlexEl</Typography>
				<Typography variant="body1" mt={1}>
					Fordelen ved FlexEl er, at du har mulighed for at udnytte de variable timepriser, og
					dermed kan flytte dit forbrug til de tidspunkter, hvor strømmen er billigst. Det er fx
					særlig fordelagtigt, hvis du har elbil eller plugin-hybridbil.
				</Typography>
				<Typography variant="body1" mt={2}>
					<a href="https://norlys.dk/el/flexel/#video" target="_blank" rel="noreferrer">
						Se video om FlexEl
					</a>
				</Typography>
				<Typography variant="body1" mt={2}>
					<a href="https://norlys.dk/el/elpriser/#prisudvikling" target="_blank" rel="noreferrer">
						Se den historiske prisudvikling for FlexEl
					</a>
				</Typography>
			</Box>
		</Box>
	);
}

export function getMånedsElInformationText() {
	return (
		<Box>
			<Box>
				<Typography variant="body1bold">Bliv klogere på MånedsEl</Typography>
				<Typography variant="body1" mt={1}>
					Hvis du gerne vil kende din elpris måned for måned, er MånedsEl et godt valg for dig.
				</Typography>
				<Typography variant="body1" mt={1}>
					Du slipper altså for at spekulere over, hvornår på døgnet du bruger strømmen. Din elpris
					bliver kun ændret én gang pr. måned. Det giver dig en større forudsigelighed samtidig med,
					at elprisen løbende afspejler udviklingen i elmarkedet.
				</Typography>
				<Typography variant="body1" mt={2}>
					<a href="https://norlys.dk/el/maanedsel/#video" target="_blank" rel="noreferrer">
						Se video om MånedsEl
					</a>
				</Typography>
				<Typography variant="body1" mt={2}>
					<a href="https://norlys.dk/el/elpriser/#prisudvikling" target="_blank" rel="noreferrer">
						Se den historiske prisudvikling for MånedsEl
					</a>
				</Typography>
			</Box>
		</Box>
	);
}

export function getKombiElInformationText() {
	return (
		<Box>
			<Box>
				<Typography variant="body1bold">Bliv klogere på KombiEl</Typography>
				<Typography variant="body1" mt={1}>
					Med KombiEl har du mulighed for at udnytte fordelene ved både en fast og variabel
					timepris. Derfor er KombiEl et godt valg til dig, som ønsker en elaftale, hvor en del af
					prisen varierer, mens du samtidig er sikret en fast pris, der minimerer store prisudsving.
				</Typography>
				<Typography variant="body1" mt={2}>
					KombiEl giver dig en elpris, hvor halvdelen af dit forbrug afregnes til en fast pris, og
					den anden halvdel til en variabel timepris. Du kan derfor med fordel forsøge at flytte fx
					tøjvasken til de billigste timer, mens du samtidig er sikret mod større prishop i den
					forkerte retning, fordi halvdelen af din elpris er fast et kvartal ad gangen.
				</Typography>
				<Typography variant="body1" mt={2}>
					Din elpris følger løbende markedsudviklingen både på kort og lang sigt.
				</Typography>
				<Typography variant="body1" mt={2}>
					<a href="https://norlys.dk/el/kombiel/#video" target="_blank" rel="noreferrer">
						Se video om KombiEl
					</a>
				</Typography>
				<Typography variant="body1" mt={2}>
					<a href="https://norlys.dk/el/elpriser/#prisudvikling" target="_blank" rel="noreferrer">
						Se den historiske prisudvikling for KombiEl
					</a>
				</Typography>
			</Box>
		</Box>
	);
}

export function getChargingStandEjeInformationText() {
	return (
		<Box>
			<Box>
				<Typography variant="body1bold">Bliv klogere på din ladeløsning</Typography>
				<Typography variant="body1" mt={1}>
					Hos Norlys får du en intelligent ladeboks, som er nem at betjene. Du vælger selv, om du
					vil leje eller købe din ladeboks.
				</Typography>
				<Typography variant="body1" mt={2}>
					Hvis du lejer din ladeboks, er startprisen lav, og derefter har du en fast, månedlig
					lejepris. Køber du i stedet ladeboksen, er din startpris højere end ved leje, men din
					månedlige pris er lavere.
				</Typography>
			</Box>
		</Box>
	);
}

export function getChargingStandLejeInformationText() {
	return (
		<Box>
			<Box>
				<Typography variant="body1bold">Bliv klogere på din leje ladeløsning</Typography>
				<Typography variant="body1" mt={1}>
					Hos Norlys får du en intelligent ladeboks, som er nem at betjene. Du vælger selv, om du
					vil leje eller købe din ladeboks.
				</Typography>
				<Typography variant="body1" mt={2}>
					Hvis du lejer din ladeboks, er startprisen lav, og derefter har du en fast, månedlig
					lejepris. Køber du i stedet ladeboksen, er din startpris højere end ved leje, men din
					månedlige pris er lavere.
				</Typography>
			</Box>
		</Box>
	);
}
