import styled from 'styled-components'

const SkeletonContainer = styled.div`
 background-color:white;
 width:100%;
 height:100px;
 border-radius:5px;
 border: dashed 2px lightgrey;
 opacity:0.5;
 margin-top:20px;
 
 @media (max-width: 768px) {
   display:none !important;
}

`;

export default SkeletonContainer;