import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FontStyles from "./Fonts/fontStyles";
import { BrowserRouter as Router } from 'react-router-dom';

declare global {
  interface Window {
    _env_: any;
    dataLayer: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <FontStyles/>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
