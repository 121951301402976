import * as React from "react";
import {
	Accordion,
	AccordionMinimumHeight,
	Boxbutton,
	BoxbuttonColorTheme,
	DatePickerComponent,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import "./MovingInStep.scss";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { InfoTooltip } from "../../../../Utils/UI/InfoBadge/InfoBadge";
import { Box } from "@mui/material";

const MovingInStep = ({
	nextStep,
	previousStep,
	order,
	open,
	toggleOpen,
	setOrder,
}: IStepInterface) => {
	const [firstButtonSelected, setFirstButtonSelected] = React.useState(false);
	const [secondButtonSelected, setSecondButtonSelected] = React.useState(false);
	const partnerContentContext = React.useContext(PartnerContentContext);

	const getMinDate = (): string => {
		let now = new Date();
		let minDate = new Date(now.setDate(now.getDate() - 5));
		return new Date(
			`${minDate.getFullYear()}-${minDate.getMonth() + 1}-${minDate.getDate()}`.replace(/-/g, "/")
		).toDateString();
	};

	const getMaxDate = (): string => {
		let now = new Date();
		let maxDate = new Date(now.setDate(now.getDate() + 60));
		return new Date(
			`${maxDate.getFullYear()}-${maxDate.getMonth() + 1}-${maxDate.getDate()}`.replace(/-/g, "/")
		).toDateString();
	};

	React.useEffect(() => {
		if (firstButtonSelected) {
			(document.querySelector(".calender-container") as HTMLElement).style.width =
				calculateDatepickerWidth();
		}
	}, [firstButtonSelected]);

	const calculateDatepickerWidth = (): string => {
		if (window.innerWidth < 768) {
			let width = (document.querySelector(".box-button") as HTMLElement)?.clientWidth * 2 - 80;
			return width + "px";
		}

		return "455px";
	};

	return (
		<Accordion
			firstButtonId="movingInStepReturn"
			secondButtonId="movingInStepForward"
			minimumHeight={AccordionMinimumHeight.EXTRASMALL}
			firstButtonLabel={
				partnerContentContext.movingInStep.backButton
					? partnerContentContext.movingInStep.backButton
					: "Tilbage"
			}
			firstButton={previousStep}
			firstButtonOutline={true}
			secondButtonLabel={
				partnerContentContext.movingInStep.nextButton
					? partnerContentContext.movingInStep.nextButton
					: "Næste"
			}
			secondButton={firstButtonSelected || secondButtonSelected ? nextStep : undefined}
			secondButtonDisabled={
				(!firstButtonSelected && !secondButtonSelected) || (firstButtonSelected && !order.startDate)
			}
			label={partnerContentContext.movingInStep.title}
			open={open}
			click={toggleOpen}>
			<p>{partnerContentContext.movingInStep.cohabLabel}</p>
			<div className="movingin-wrapper">
				<Boxbutton
					height={60}
					label={partnerContentContext.movingInStep.firstBoxButton}
					click={() => {
						setOrder({ ...order, cohabCpr: "", cohabFullName: "" });
						setFirstButtonSelected(true);
						setSecondButtonSelected(false);
					}}
					theme={firstButtonSelected ? BoxbuttonColorTheme.GREEN : BoxbuttonColorTheme.WHITE}
					border={true}></Boxbutton>
				<Boxbutton
					height={60}
					label={partnerContentContext.movingInStep.secondBoxButton}
					click={() => {
						setOrder({ ...order, startDate: undefined });
						setFirstButtonSelected(false);
						setSecondButtonSelected(true);
					}}
					theme={secondButtonSelected ? BoxbuttonColorTheme.GREEN : BoxbuttonColorTheme.WHITE}
					border={true}></Boxbutton>
			</div>

			{firstButtonSelected && (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<DatePickerComponent
						width={600}
						placeholder={
							partnerContentContext.movingInStep.datePlaceholder
								? partnerContentContext.movingInStep.datePlaceholder
								: "Indflytningsdato"
						}
						minDate={getMinDate()}
						maxDate={getMaxDate()}
						change={(date): any => {
							setOrder({ ...order, startDate: date });
						}}
					/>
					<Box style={{ margin: "0.5rem 0 0 4.5rem" }}>
						<InfoTooltip placement="right" message="5 dage tilbage / 2 mdr. frem." />
					</Box>
				</Box>
			)}
		</Accordion>
	);
};

export default MovingInStep;
