export enum StepEnum {
	NameAddressStep,
	ProductStep,
	CprStep,
	MovingInStep,
	CohabitantStep,
	ContactInfoStep,
	SalesStep,
	SummaryStep,
}
