import * as React from "react";
import {
	Accordion,
	AccordionBackgroundColor,
	AccordionMinimumHeight,
	ButtonColorEnum,
	DashedLine,
	Modal,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import "./ProductStep.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { ProductCard } from "../../../../Utils/UI/ProductCard/ProductCard";
import { ProductCategory } from "../../../../../Models/Enums/ProductCategoryEnum";
import { IPartnerProduct } from "../../../../../Models/PartnerProductModel";
import { Box, Typography, styled } from "@mui/material";
import { ProductService } from "../../../../../Services/PartnerProductService";
import { EmobilityStep } from "../EmobilityStep/EmobilityStep";
import { IPriceDetailsProps, PriceDetail } from "../../../../Utils/UI/PriceDetails/PriceDetail";
import { ITransportAndCharges } from "../../../../../Models/TransportAndCharges";

const ProductStep = ({
	nextStep,
	previousStep,
	order,
	open,
	toggleOpen,
	setOrder,
}: IStepInterface) => {
	const [chosenElCardKey, setChosenElCardKey] = useState("");
	const [chosenEmobCardKey, setChosenEmobCardKey] = useState("");
	const [electricityProducts, setElectricityProducts] = useState(Array<IPartnerProduct>());
	const [chargingStandProducts, setChargingStandProducts] = useState(Array<IPartnerProduct>());
	const [modalVisible, setModalVisible] = React.useState(false);
	const [loading, setIsLoading] = React.useState(false);
	const [priceDetailProps, setPriceDetailProps] = useState<IPriceDetailsProps>({
		price: 0,
		unit: "",
		renderInfoText: true,
		subcategory: "",
		category: "",
		discounts: {
			installationDiscount: false,
			subscriptionDiscount: false,
			substitutionDiscount: false,
		},
		emobilityInstallationDiscount: undefined,
		emobilitySubscriptionDiscount: undefined,
		emobilitySubstitutionDiscount: undefined,
		substitution: false,
	});
	const partnerContentContext = useContext(PartnerContentContext);

	const nextButtonActiveMemo = useMemo(() => {
		if (
			partnerContentContext.isElectricityRequired &&
			partnerContentContext.isChargingStandRequired
		) {
			return (
				chosenElCardKey.length > 0 &&
				chosenEmobCardKey.length > 0 &&
				order.emobilityWishedInstallationMonthName.length > 0
			);
		} else if (
			partnerContentContext.isElectricityRequired &&
			!partnerContentContext.isChargingStandRequired
		) {
			if (chosenEmobCardKey.length > 0)
				return chosenElCardKey.length > 0 && order.emobilityWishedInstallationMonthName.length > 0;
			else return chosenElCardKey.length > 0;
		} else if (
			!partnerContentContext.isElectricityRequired &&
			partnerContentContext.isChargingStandRequired
		) {
			return chosenEmobCardKey.length > 0 && order.emobilityWishedInstallationMonthName.length > 0;
		} else {
			if (chosenEmobCardKey.length > 0)
				return order.emobilityWishedInstallationMonthName.length > 0;
			else return chosenElCardKey.length > 0;
		}
	}, [
		partnerContentContext,
		chosenEmobCardKey,
		chosenElCardKey,
		order.emobilityWishedInstallationMonthName,
	]);

	useEffect(() => {
		setIsLoading(true);
		getProducts().then(() => setIsLoading(false));
	}, [order.deliveryAddressId]);

	useEffect(() => {
		setOrder({
			...order,
			chosenProducts: {
				chargingstandProduct: chargingStandProducts.find((p) => p.id === chosenEmobCardKey),
				electricityProduct: electricityProducts.find((p) => p.id === chosenElCardKey),
			},
			emobilitySelected: chosenEmobCardKey.length > 0,
			emobilityWishedInstallationMonth:
				chosenEmobCardKey.length > 0 ? order.emobilityWishedInstallationMonth : undefined,
			emobilityWishedInstallationMonthName:
				chosenEmobCardKey.length > 0 ? order.emobilityWishedInstallationMonthName : "",
		});
	}, [chosenElCardKey, chosenEmobCardKey]);

	useEffect(() => {
		if (electricityProducts.length > 0) {
			getTransportAndCharges();
		}
	}, [electricityProducts]);

	async function getProducts() {
		let productIds = Array<any>();
		if (partnerContentContext.electricityProductIds) {
			partnerContentContext.electricityProductIds.forEach((id) => productIds.push(id.value));
		}
		if (partnerContentContext.chargingStandProductIds) {
			partnerContentContext.chargingStandProductIds.forEach((id) => productIds.push(id.value));
		}

		await ProductService.getProducts(productIds)
			.then((data) => {
				sortProductsByCategory(data);
			})
			.then(() => {
				checkAutomaticSelection();
			})
			.catch((error) => {});
	}

	async function getTransportAndCharges() {
		if (partnerContentContext.electricityProductIds.length > 0) {
			await ProductService.getTransportAndCharges(electricityProducts[0].crmProductId)
				.then((transportAndCharges: ITransportAndCharges) => {
					partnerContentContext.transportAndCharges = transportAndCharges;
				})
				.catch((error) => {});
		}
	}

	function checkAutomaticSelection() {
		if (electricityProducts.length === 1) {
			if (partnerContentContext.isElectricityRequired || chargingStandProducts.length === 0)
				setChosenElCardKey(electricityProducts[0].id);
		}
		if (chargingStandProducts.length === 1) {
			if (partnerContentContext.isChargingStandRequired || electricityProducts.length === 0)
				setChosenEmobCardKey(chargingStandProducts[0].id);
		}
	}

	function sortProductsByCategory(products: Array<IPartnerProduct>) {
		let electricityProducts = Array<IPartnerProduct>();
		let chargingStandProducts = Array<IPartnerProduct>();
		if (products.length > 0) {
			products.forEach((product) => {
				if (product !== null) {
					product.category === ProductCategory.El
						? electricityProducts.push(product)
						: chargingStandProducts.push(product);
				}
			});
		}

		setElectricityProducts(electricityProducts);
		setChargingStandProducts(chargingStandProducts);
	}

	function isEastInstallation(): boolean {
		return parseInt(order.addressRequest.postCode) < 5000;
	}

	function handlePriceDetailButtonClick(priceDetailProps: IPriceDetailsProps) {
		setPriceDetailProps(priceDetailProps);
		setModalVisible(true);
	}

	return (
		<Accordion
			loading={loading}
			loadingText={partnerContentContext.productStep.loading}
			loadingTitle={partnerContentContext.stepLoader.title}
			backgroundColor={AccordionBackgroundColor.GREY}
			label={partnerContentContext.productStep.title}
			open={open}
			click={toggleOpen}
			firstButtonLabel={
				partnerContentContext.productStep.backButton
					? partnerContentContext.productStep.backButton
					: "Tilbage"
			}
			firstButtonOutline={true}
			firstButton={previousStep}
			secondButtonLabel={
				partnerContentContext.productStep.nextButton
					? partnerContentContext.productStep.nextButton
					: "Næste"
			}
			secondButtonType={ButtonColorEnum.DARK_GREEN}
			secondButtonDisabled={!nextButtonActiveMemo}
			secondButton={nextStep}
			minimumHeight={AccordionMinimumHeight.SMALL}
			firstButtonId="productStepReturn"
			secondButtonId="productStepForward"
			secondButtonShadow={true}>
			<ProductsContainer>
				{electricityProducts.length > 0 && (
					<>
						<CategoryDivider>
							<Typography variant="h2">Elprodukt</Typography>
						</CategoryDivider>
						<ProductCategoryContainer>
							{electricityProducts.map((product) => (
								<ProductCard
									key={product.id}
									product={product}
									chosen={chosenElCardKey === product.id}
									useMainPrice={isEastInstallation()}
									setChosenOrderCardKey={setChosenElCardKey}
									onButtonClick={(priceDetailProps: IPriceDetailsProps) =>
										handlePriceDetailButtonClick(priceDetailProps)
									}
									discounts={{
										installationDiscount: false,
										subscriptionDiscount: false,
										substitutionDiscount: false,
									}}
									substitution={false}
								/>
							))}
						</ProductCategoryContainer>
					</>
				)}
				{chargingStandProducts.length > 0 && electricityProducts.length > 0 && (
					<DashedLine marginBottom="30" marginTop="30" />
				)}
				{chargingStandProducts.length > 0 && (
					<>
						<CategoryDivider>
							<Typography variant="h2">Ladeløsning</Typography>
						</CategoryDivider>
						<ProductCategoryContainer>
							{chargingStandProducts.map((product) => (
								<ProductCard
									key={product.id}
									product={product}
									chosen={chosenEmobCardKey === product.id}
									useMainPrice={product.secondaryPrice === 0}
									setChosenOrderCardKey={setChosenEmobCardKey}
									onButtonClick={(priceDetailProps: IPriceDetailsProps) =>
										handlePriceDetailButtonClick(priceDetailProps)
									}
									discounts={{
										installationDiscount: partnerContentContext.emobilityInstallationDiscountActive,
										subscriptionDiscount: partnerContentContext.emobilitySubscriptionDiscountActive,
										substitutionDiscount: partnerContentContext.emobilitySubstitutionDiscountActive,
									}}
									substitution={order.emobilityRemovalOfChargingStand ?? false}
								/>
							))}
						</ProductCategoryContainer>
						{order.emobilitySelected && <EmobilityStep order={order} setOrder={setOrder} />}
					</>
				)}
			</ProductsContainer>
			<Modal
				visible={modalVisible}
				onClosing={() => {
					setModalVisible(false);
				}}>
				<PriceDetail
					price={priceDetailProps.price}
					unit={priceDetailProps?.unit}
					renderInfoText={priceDetailProps.renderInfoText}
					subcategory={priceDetailProps.subcategory}
					category={priceDetailProps.category}
					discounts={priceDetailProps.discounts}
					emobilityInstallationDiscount={priceDetailProps.emobilityInstallationDiscount}
					emobilitySubscriptionDiscount={priceDetailProps.emobilitySubscriptionDiscount}
					emobilitySubstitutionDiscount={priceDetailProps.emobilitySubstitutionDiscount}
					substitution={priceDetailProps.substitution}
				/>
			</Modal>
		</Accordion>
	);
};

export default ProductStep;

const ProductsContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 0px -120px 0px;

	@media (max-width: 768px) {
		margin: 0 0 5rem 0;
	}
`;

const ProductCategoryContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: space-evenly;
	margin-bottom: 1rem;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 2rem;
	}
`;

const CategoryDivider = styled(Box)`
	margin: 0 2rem 1rem;

	@media (max-width: 768px) {
		margin: 0.5rem 2rem 0;
	}
`;
