import { Button, ButtonColorEnum } from "norlys-ui-components";
import { OrderModel } from "../../../../../Models/OrderModel";
import { OrderResult } from "../../../../../Models/CreateOrderResult";
import "./ReceiptStep.scss";
import { useContext, useEffect, useState } from "react";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { DashedLine } from "../../../../Utils/DashedLine/DashedLine";

export interface IReceiptInterface {
	order: OrderModel;
	orderResult: Array<OrderResult>;
}

const ReceiptStep = ({ order, orderResult }: IReceiptInterface) => {
	const partnerContentContext = useContext(PartnerContentContext);
	const DOMPurify = require("dompurify")(window);

	const getBullets = (): Array<string> => {
		let bullets = partnerContentContext.reciept.bullets;

		if (bullets) {
			return bullets;
		}

		return [];
	};

	const getInfoText = (): string => {
		let infoText = partnerContentContext.reciept.infoText;
		if (infoText) {
			infoText = infoText.replace("EMAILPLACEHOLDER", `<b>${order.email}</b>`);
			return infoText;
		}
		return "";
	};

	const calculateExpirationDate = (
		expirationMonths: number,
		issueDate: Date | undefined
	): string => {
		if (expirationMonths && issueDate) {
			let date = new Date(issueDate);
			date.setMonth(date.getMonth() + expirationMonths);
			return date.toLocaleDateString().replaceAll(".", "/");
		}
		return "";
	};

	function calculateVoucherTotalPrice(orderResult: Array<OrderResult>): number {
		let totalPrice = 0;
		for (let i = 0; i < orderResult.length; i++) {
			totalPrice += orderResult[i].voucherValue ?? 0;
		}
		return totalPrice;
	}

	function numberOfVouchers(orderResult: Array<OrderResult>): number {
		let numberOfVouchers = 0;

		orderResult.forEach((result) => {
			if (result.code !== undefined && result.voucherValue !== 0) numberOfVouchers++;
		});

		return numberOfVouchers;
	}

	return (
		<div className="receipt-container">
			<div>
				<h1>{partnerContentContext.reciept.title}</h1>
				<h3>{partnerContentContext.reciept.subTitle}</h3>
				<div className="receipt-content">
					<div className="receipt-text">
						<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getInfoText()) }}></div>
					</div>
					<div>
						<p>
							<b>{partnerContentContext.reciept.bulletsTitle}</b>
						</p>
						<div className="bullets-section">
							<ul>
								{getBullets().map((item, index) => {
									return <li key={index}>{item}</li>;
								})}
							</ul>
						</div>
					</div>
					{numberOfVouchers(orderResult) > 0 && (
						<div className="voucher-content">
							<div className="voucher-title">{partnerContentContext.reciept.voucherTitle}</div>
							<div className="voucher-info">
								{`Din samlede værdi er ${calculateVoucherTotalPrice(orderResult)}kr.`}
							</div>
							<div className="voucher-abovetext">
								{orderResult && numberOfVouchers(orderResult) > 1
									? "Her er dine rabatkoder"
									: "Her er din rabatkode"}
							</div>
							{orderResult?.map((voucher) => {
								if (voucher.code !== undefined) {
									return (
										<div>
											<div className="voucher-code">{voucher.code}</div>
											<div
												style={{ margin: "0.5rem 0" }}>{`Værdi: ${voucher.voucherValue}kr. `}</div>
											<div>
												{`Udløbsdato: ${calculateExpirationDate(
													voucher.expirationMonths,
													voucher.issueDate
												)}`}
											</div>
										</div>
									);
								}
							})}
							<div className="voucher-belowtext">
								{partnerContentContext.reciept.voucherTextBelow}
							</div>

							<div className="voucher-footer">
								<DashedLine marginBottom="20" width="95"></DashedLine>
								<div>{partnerContentContext.reciept.voucherFooterText}</div>
								<div>{partnerContentContext.reciept.voucherExpirationFooterText}</div>
							</div>
						</div>
					)}

					<div className={`advantages-text ${"left-aligned"}`}>
						{partnerContentContext.reciept.advantagesText}
					</div>
					<div className="receipe-footer">
						<Button
							withShadow={true}
							color={ButtonColorEnum.DARK_GREEN}
							label={partnerContentContext.reciept.buttonText}
							onClick={() => {
								window.open(partnerContentContext.reciept.buttonUrl, "_blank");
							}}></Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReceiptStep;
