import axios from "axios";
import ApiResponseModel from "../Models/ApiResponseModel";
import { PartnerContent } from "../Models/PartnerContentModel";
import VoucherModel from "../Models/VoucherModel";
import { APIUrl } from "../constants";
import CallMeUpModel from "../Models/CallMeUpModel";
import { OrderRequest } from "../Models/OrderRequest";

const publicIp = require("react-public-ip");

const getCreateOrderUrl = (recaptchaToken: string) => {
	return `${APIUrl}/api/v1/Order/create-order?captchaToken=${recaptchaToken}`;
	//return `https://localhost:44309/api/v1/Order/create-order?captchaToken=${recaptchaToken}`;
};

const getPriceByProductUrl = (productId: string, addressId: string, partnerId: string) => {
	return `${APIUrl}/api/v1/Product/get-price-by-product?productId=${productId}&addressId=${addressId}&partnerId=${partnerId}`;
};

const getIDQMatchUrl = (cpr: string, name: string) => {
	return `${APIUrl}/api/v1/IDQ/match-name-on-cpr?cpr=${cpr}&name=${name}`;
};

const getDatahubMatchUrl = (name: string) => {
	return `${APIUrl}/api/v1/Datahub/match-name-on-address?name=${name}`;
};

const getSonwinMatchUrl = (addressId: string) => {
	return `${APIUrl}/api/v1/Customer/customer-exists/${addressId}`;
};

const getPartnerContentUrl = (partner: string) => {
	return `${APIUrl}/api/v1/PartnerContent/get-partner-content?partner=${partner}`;
};

const getAddressHasOrderUrl = (addressId: string) => {
	return `${APIUrl}/api/v1/Order/address-has-order?addressGuid=${addressId}`;
};

const getVoucherStatusUrl = (partnerGuid: string, voucherCode: string) => {
	return `${APIUrl}/api/v1/Voucher/voucher-status?partnerGuid=${partnerGuid}&code=${voucherCode}`;
};

const getUseVoucherUrl = (voucherId: string, shopId: string, sellerId: string) => {
	return `${APIUrl}/api/v1/Voucher/use-voucher?voucherId=${voucherId}&shopId=${shopId}&sellerId=${sellerId}`;
};

const getCallMeUpLeadUrl = () => {
	return `${APIUrl}/api/v1/CallMeUp/create-call-me-up-lead`;
};

const getHeadersForIdq = async () => {
	const ip = (await publicIp.v4()) || "";
	return { headers: { "X-Forwarded-For": ip } };
};

const getVoucherHeaders = () => {
	return { headers: { "Api-Key": "" } };
};

const getPriceByProduct = async (
	addressId: string,
	productId: string,
	partnerId: string
): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();

		try {
			let result = await axios.get(getPriceByProductUrl(productId, addressId, partnerId));

			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			resolve(response);
		}
	});
};

const submitOrder = async (
	recaptchaToken: string,
	model: OrderRequest
): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();

		try {
			let result = await axios.post(getCreateOrderUrl(recaptchaToken), model);
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Der skete en fejl";
			resolve(response);
		}
	});
};

const getAddressHasOrderMatch = (addressId: string): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();

		try {
			let result = await axios.get(getAddressHasOrderUrl(addressId));
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				response.status = result.status;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				response.status = result.status;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			response.status = 500;
			resolve(response);
		}
	});
};

const getIDQMatch = async (CPR: string, name: string): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();
		try {
			let headers = await getHeadersForIdq();
			let result = await axios.get(getIDQMatchUrl(CPR, name), headers);
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				response.status = result.status;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				response.status = result.status;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			response.status = error.response.status ? error.response.status : 500;
			resolve(response);
		}
	});
};

const getDatahubMatch = async (name: string, model: any): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();
		try {
			let result = await axios.post(getDatahubMatchUrl(name), model);
			response.status = result.status;
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				response.status = result.status;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				response.status = result.status;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			response.status = 500;
			resolve(response);
		}
	});
};

const getSonwinMatch = async (addressId: string, company: string): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();
		try {
			let result = await axios.get(getSonwinMatchUrl(addressId), { headers: { company: company } });
			response.status = result.status;
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				response.status = result.status;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				response.status = result.status;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			response.status = 500;
			resolve(response);
		}
	});
};

const getPartnerContent = async (partner: string): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();

		try {
			let result = await axios.get(getPartnerContentUrl(partner));
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			resolve(response);
		}
	});
};

const getPartnerContentObject = async (partner: string): Promise<PartnerContent> => {
	var result = await getPartnerContent(partner);
	var content = new PartnerContent();
	if (result.isSuccessful) {
		if (result.data !== "NotFound") {
			content = result.data;
			content.isValid = true;
		} else {
			content.isValid = false;
		}
	}
	return content;
};

const getVoucherStatus = async (
	partnerGuid: string,
	voucherCode: string
): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();

		try {
			let result = await axios.get(
				getVoucherStatusUrl(partnerGuid, voucherCode),
				getVoucherHeaders()
			);
			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				response.status = result.status;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			response.status = error.response?.status;
			resolve(response);
		}
	});
};

const getVoucherStatusObject = async (
	partnerGuid: string,
	voucherCode: string
): Promise<VoucherModel> => {
	var result = await getVoucherStatus(partnerGuid, voucherCode);
	var content = new VoucherModel();
	if (result.isSuccessful) {
		return (content = result.data);
	}

	return content;
};

const postUseVoucher = async (
	voucherId: string,
	shopId: string,
	sellerId: string
): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();
		try {
			let result = await axios.post(
				`${getUseVoucherUrl(voucherId, shopId, sellerId)}`,
				null,
				getVoucherHeaders()
			);

			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			resolve(response);
		}
	});
};

const postCallMeUpLead = async (cmuModel: CallMeUpModel): Promise<ApiResponseModel> => {
	return new Promise(async (resolve, reject) => {
		let response = new ApiResponseModel();
		try {
			let result = await axios.post(getCallMeUpLeadUrl(), cmuModel);

			if (result.status.toString().startsWith("2")) {
				response.isSuccessful = true;
				response.data = result.data;
				resolve(response);
			} else {
				response.isSuccessful = false;
				response.error = result.statusText;
				resolve(response);
			}
		} catch (error: any) {
			response.isSuccessful = false;
			response.error = error ?? "Ukendt fejl opstod";
			resolve(response);
		}
	});
};

export {
	getPriceByProduct,
	submitOrder,
	getIDQMatch,
	getSonwinMatch,
	getDatahubMatch,
	getPartnerContentObject,
	getAddressHasOrderMatch,
	getVoucherStatusObject,
	postUseVoucher,
	postCallMeUpLead,
};
