import {
	Accordion,
	AccordionMinimumHeight,
	ButtonColorEnum,
	InputComponent,
	ValidationTypeEnum,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import { useContext, useMemo, useState } from "react";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { IInputValidationResult } from "norlys-ui-components/lib/components/input-component/Input-Component";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { StoreChain } from "../../../../../Models/PartnerContentModel";

interface IOption {
	label: string;
	id: number;
	name: string;
	installationNumber: number;
}

export const SalesStep = ({
	nextStep,
	previousStep,
	order,
	open,
	toggleOpen,
	setOrder,
}: IStepInterface) => {
	const [salesPersonError, setSalesPersonError] = useState<boolean>(false);
	const partnerContentContext = useContext(PartnerContentContext);

	function handleSalesIdChange(input: string): any {
		setSalesPersonError(input.length > 0 ? !/^\S+[a-zA-ZæÆøØåÅ\s\d]+$/.test(input) : false);
		setOrder({ ...order, salesPersonId: input });
	}

	function handleShopIdChange(text: IInputValidationResult): any {
		setOrder({ ...order, shopId: text.value });
	}

	function handleStoreChainChange(value: IOption | null) {
		if (value) {
			setOrder({
				...order,
				shopInstallationNumber: value.installationNumber,
				shopId: value.id.toString(),
				shopName: value.name,
			});
		} else {
			setOrder({
				...order,
				shopInstallationNumber: undefined,
				shopId: "",
				shopName: "",
			});
		}
	}

	function getStoreChainOptions(storeChains: Array<StoreChain>): Array<IOption> {
		const options: Array<IOption> = storeChains.map((storeChain) => {
			if (storeChain.installationNumber === undefined)
				storeChain.installationNumber = Number(partnerContentContext.partnerInstallationNumber);
			return {
				label: `${storeChain.name}${
					storeChain.installationNumber === undefined ? " - " + storeChain.installationNumber : ""
				}${storeChain.storeNumber > 0 ? " - " + storeChain.storeNumber : ""} `,
				id: storeChain.storeNumber,
				name: storeChain.name,
				installationNumber: storeChain.installationNumber,
			};
		});
		return options;
	}

	const formValid = useMemo(() => {
		if (order.salesPersonId.length === 0 || salesPersonError) return false;
		const onlyDigitsInShopId = /^\d+$/.test(order.shopId);
		if (!onlyDigitsInShopId) return false;
		if (order.chosenProducts.chargingstandProduct !== undefined) return true;
		if (partnerContentContext.isStoreChain && order.shopInstallationNumber === undefined)
			return false;
		return true;
	}, [
		order.chosenProducts,
		order.shopId,
		order.shopInstallationNumber,
		salesPersonError,
		order.salesPersonId,
	]);

	return (
		<Accordion
			loading={false}
			loadingText={partnerContentContext.stepLoader.text}
			loadingTitle={partnerContentContext.stepLoader.title}
			label={partnerContentContext.salesInformationStep?.title || "Sælgeroplysninger"}
			open={open}
			click={toggleOpen}
			firstButtonLabel={partnerContentContext.salesInformationStep?.backButton || "Tilbage"}
			firstButtonOutline={true}
			firstButton={previousStep}
			secondButtonLabel={partnerContentContext.salesInformationStep?.nextButton || "Næste"}
			secondButtonType={ButtonColorEnum.DARK_GREEN}
			secondButton={nextStep}
			secondButtonDisabled={!formValid}
			minimumHeight={AccordionMinimumHeight.SMALL}
			firstButtonId="SalesStepReturn"
			secondButtonId="SalesStepForward"
			secondButtonShadow={true}>
			<Box>
				<Box display={"flex"}>
					<Box flex={1}>
						<TextField
							error={salesPersonError}
							variant="outlined"
							defaultValue={order.salesPersonId}
							placeholder="Sælger ID"
							onChange={(e) => handleSalesIdChange(e.target.value)}
							inputProps={{ maxLength: 40 }}
							sx={{ backgroundColor: "white" }}
							fullWidth={true}
						/>
						{salesPersonError && (
							<Typography mt={1} sx={{ color: "red" }}>
								Må ikke indeholde specialtegn.
							</Typography>
						)}
					</Box>
					{partnerContentContext.isStoreChain ? (
						<Box flex={1} ml={"10px"}>
							<Autocomplete
								size="medium"
								defaultValue={{ label: "Vælg afdeling", id: -1, name: "", installationNumber: 0 }}
								disablePortal
								onChange={(e, value) => {
									handleStoreChainChange(value);
								}}
								options={getStoreChainOptions(partnerContentContext.storeChains)}
								sx={{ backgroundColor: "white" }}
								fullWidth={true}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Box>
					) : (
						<Box flex={1} ml={"10px"}>
							<InputComponent
								value={order.shopId}
								placeholder={
									partnerContentContext.salesInformationStep?.shopIdPlaceholder || "Butiksnummer"
								}
								validationType={ValidationTypeEnum.NUMBER}
								customNumberValidationError="Må kun indholde tal"
								change={handleShopIdChange}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Accordion>
	);
};
