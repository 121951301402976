import React from 'react';
import './Column.scss'

interface IColumn {
    children?: any;
    fullWidth?:boolean;
    fullWidthMobile?:boolean;
    noTooltip?:boolean;
}

const Column = ({ children, fullWidth, fullWidthMobile, noTooltip }: IColumn) => {

    return (
        <div className={`column-section ${fullWidth ? ' column-fullWidth' : ''}${React.Children.count(children) === 1 ? ' one-element' : ''}${fullWidthMobile ? ' fullWidth-mobile' : ''} ${noTooltip ? 'no-tooltip' : ''}`}>
            {children}
        </div>
    );
};

export default Column;