import { createTheme } from "@mui/material";
import { Colors } from "./colors";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h4light: React.CSSProperties;
    h5light: React.CSSProperties;
    subtitle3: React.CSSProperties;
    body1list: React.CSSProperties;
    body1bold: React.CSSProperties;
    body1boldList: React.CSSProperties;
    body2list: React.CSSProperties;
    body3: React.CSSProperties;
    body3list: React.CSSProperties;
    detailBold: React.CSSProperties;
    detailBoldList: React.CSSProperties;
    detail: React.CSSProperties;
    detailList: React.CSSProperties;
    petitBold: React.CSSProperties;
    petitBoldList: React.CSSProperties;
    petit: React.CSSProperties;
    petitList: React.CSSProperties;
    secondaryButton1: React.CSSProperties;
    secondaryButton2: React.CSSProperties;
    secondaryButton3: React.CSSProperties;
    numbers1: React.CSSProperties;
    numbers2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h4light?: React.CSSProperties;
    h5light?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    body1list?: React.CSSProperties;
    body1bold?: React.CSSProperties;
    body1boldList?: React.CSSProperties;
    body2list?: React.CSSProperties;
    body3?: React.CSSProperties;
    body3list?: React.CSSProperties;
    detailBold?: React.CSSProperties;
    detailBoldList?: React.CSSProperties;
    detail?: React.CSSProperties;
    detailList?: React.CSSProperties;
    petitBold?: React.CSSProperties;
    petitBoldList?: React.CSSProperties;
    petit?: React.CSSProperties;
    petitList?: React.CSSProperties;
    secondaryButton1?: React.CSSProperties;
    secondaryButton2?: React.CSSProperties;
    secondaryButton3?: React.CSSProperties;
    numbers1?: React.CSSProperties;
    numbers2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h4light: true;
    h5light: true;
    subtitle3: true;
    body1list: true;
    body1bold: true;
    body1boldList: true;
    body2list: true;
    body3: true;
    body3list: true;
    detailBold: true;
    detailBoldList: true;
    detail: true;
    detailList: true;
    petitBold: true;
    petitBoldList: true;
    petit: true;
    petitList: true;
    secondaryButton1: true;
    secondaryButton2: true;
    secondaryButton3: true;
    numbers1: true;
    numbers2: true;
    // Disabled variants
    h6: false;
    caption: false;
    overline: false;
  }
}

// Update the button's variant prop options
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    norlys: true;
  }
}

const theme = createTheme({
  typography: {
    // Disabled variants
    h6: undefined,
    caption: undefined,
    overline: undefined,
  },
  palette: {
    info: {
      main: Colors.greenBright,
    },
    primary: {
      main: Colors.greenDark,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: Colors.white,
          boxShadow: "0 0.5rem 1.5rem rgba(50, 50, 50, .12)",
          fontFamily: "NORLYSTextRegular",
          color: Colors.grey100,
          fontSize: "1rem",
          lineHeight: "1.375rem",
          padding: "1.25rem",
          borderRadius: ".5rem",
        },
        arrow: {
          color: Colors.white,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "norlys" },
          style: {
            borderRadius: "3.125rem",
            padding: ".625rem 1.5rem .5625rem",
            textTransform: "uppercase",
            boxShadow: "0 .25rem .5rem rgba(0, 0, 0, .16)",
            backgroundColor: Colors.greenDark,
            color: Colors.white,
            "&:hover": {
              margin: "-.125rem 0",
              padding: ".75rem 1.625rem .6875rem",
              backgroundColor: Colors.greenDark,
              boxShadow: "0 .375rem .75rem rgba(0, 0, 0, .24)",
            },
            "&:active": {
              margin: "0",
              padding: ".625rem 1.5rem",
              backgroundColor: Colors.greenDark,
              boxShadow: "0 .25rem .5rem rgba(0, 0, 0, .20)",
            },
            "&:disabled": {
              backgroundColor: "rgba(50, 50, 50, .32)",
              color: "white",
            },
            transition: ".2s",
          },
        },
        {
          props: { variant: "norlys", color: "secondary" },
          style: {
            boxShadow: "0 .25rem .5rem rgba(0, 0, 0, .20)",
            textTransform: "none",
            backgroundColor: Colors.white,
            color: "#000000",
            "&:hover": {
              margin: "-.125rem -.125rem",
              backgroundColor: Colors.white,
            },
            "&:active": {
              margin: "0 0 0 .125rem",
            },
          },
        },
      ],
    },
  },
});

theme.typography.h1 = {
  fontFamily: "NORLYSHeadlineBold",
  fontSize: "36px",
  lineHeight: "42px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "56px",
    lineHeight: "64px",
  },
};

theme.typography.h2 = {
  fontFamily: "NORLYSHeadlineBold",
  fontSize: "26px",
  lineHeight: "32px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
    lineHeight: "48px",
  },
};

theme.typography.h3 = {
  fontFamily: "NORLYSHeadlineBold",
  fontSize: "20px",
  lineHeight: "28px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "26px",
    lineHeight: "36px",
  },
};

theme.typography.h4 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "20px",
  lineHeight: "28px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
};

theme.typography.h4light = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "20px",
  lineHeight: "28px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
};

theme.typography.h5 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};

theme.typography.h5light = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};

theme.typography.subtitle1 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
    lineHeight: "26px",
  },
};

theme.typography.subtitle2 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
};

theme.typography.subtitle3 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
};

theme.typography.body1 = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
    lineHeight: "26px",
  },
};

theme.typography.body1list = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "18px",
  lineHeight: "36px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "32px",
  },
};

theme.typography.body1bold = {
  fontFamily: "NORLYSTextBold",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
    lineHeight: "26px",
  },
};

theme.typography.body1boldList = {
  fontFamily: "NORLYSTextBold",
  fontSize: "16px",
  lineHeight: "32px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
    lineHeight: "36px",
  },
};

theme.typography.body2 = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
};

theme.typography.body2list = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "14px",
  lineHeight: "28px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "32px",
  },
};

theme.typography.body3 = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
};

theme.typography.body3list = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "12px",
  lineHeight: "26px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "28px",
  },
};

theme.typography.detailBold = {
  fontFamily: "NORLYSTextBold",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

theme.typography.detailBoldList = {
  fontFamily: "NORLYSTextBold",
  fontSize: "12px",
  lineHeight: "26px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "26px",
  },
};

theme.typography.detail = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

theme.typography.detailList = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "12px",
  lineHeight: "26px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "26px",
  },
};

theme.typography.petitBold = {
  fontFamily: "NORLYSTextBold",
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

theme.typography.petitBoldList = {
  fontFamily: "NORLYSTextBold",
  fontSize: "10px",
  lineHeight: "22px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "24px",
  },
};

theme.typography.petit = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

theme.typography.petitList = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "10px",
  lineHeight: "22px",
  fontWeight: "400",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "24px",
  },
};

theme.typography.button = {
  fontFamily: "NORLYSTextBold",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "700",
  textTransform: "uppercase",
  letterSpacing: "1px",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
};

theme.typography.secondaryButton1 = {
  fontFamily: "NORLYSTextRegular",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "400",
  textDecoration: "underline",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
};

theme.typography.secondaryButton2 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "700",
  textDecoration: "underline",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
};

theme.typography.secondaryButton3 = {
  fontFamily: "NORLYSTextBold",
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: "700",
  textDecoration: "underline",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

theme.typography.numbers1 = {
  fontFamily: "NORLYSHeadlineBold",
  fontSize: "52px",
  lineHeight: "60px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "80px",
    lineHeight: "88px",
  },
};

theme.typography.numbers2 = {
  fontFamily: "NORLYSHeadlineBold",
  fontSize: "38px",
  lineHeight: "46px",
  fontWeight: "700",
  color: Colors.grey100,
  [theme.breakpoints.up("sm")]: {
    fontSize: "56px",
    lineHeight: "64px",
  },
};

export default theme;
