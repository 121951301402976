class CallMeUpModel {
	public customerName: string = "";
	public customerPhone: string = "";
	public customerAddress: string = ""; //val.value.tekst
	public zipCode: string = ""; //address objekt zip-code
	public city: string = ""; //address objekt city
	public partnerName: string = ""; //partnerId
	public partnerGuid: string = ""; //id -
	public errorOnStep: string = ""; //step det gik galt på
}

export default CallMeUpModel;
