import "./Herobanner.scss";
import IHerobannerInterface from "../../../Interfaces/IHerobannerInterface";
import { useContext } from "react";
import PartnerContentContext from "../../../Contexts/PartnerContentContext";
import img from "../../../Assets/Images/Elektricitet-MedVind_colour.png";

const HeroBanner = ({ hide }: IHerobannerInterface) => {
	const partnerContentContext = useContext(PartnerContentContext);
	const DOMPurify = require("dompurify")(window);

	return (
		<div className={`banner-row background-banner ${hide ? "hidden-element" : ""}`}>
			<div className="hero-container">
				<div className={partnerContentContext.heroBanner.imagePlacement ? partnerContentContext.heroBanner.imagePlacement : "hero-image-right"}>
					<img
						className=""
						src={
							partnerContentContext.heroBanner.image ? partnerContentContext.heroBanner.image : img
						}
						alt="logo"
						width={partnerContentContext.heroBanner.width} 
					/>
					<h1 className="title-section">{partnerContentContext.heroBanner.title}</h1>
				</div>

				
				<p className="banner-text">
					<b> {partnerContentContext.heroBanner.boldText} </b>
				</p>

				<div
					className="text"
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(partnerContentContext.heroBanner.text, {
							ADD_ATTR: ["target"],
						}),
					}}></div>
			</div>
		</div>
	);
};
export default HeroBanner;
