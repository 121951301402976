import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import PartnerContentContext from "../../../Contexts/PartnerContentContext";
import AddressModel from "../../../Models/AddressModel";
import OrderModel from "../../../Models/OrderModel";
import ErrorStep from "../../LandingpageComponents/Wizard/Steps/ErrorStep/ErrorStep";
import CallMeUpComponent from "../CallMeUpComponent/CallMeUpComponent";
import "./NorlysErrorPage.scss";
import { StepEnum } from "../../../Models/StepEnum";

interface INorlysErrorPage {
	order: OrderModel;
	setOrder: React.Dispatch<React.SetStateAction<OrderModel>>;
	address: AddressModel;
	step: StepEnum;
}

//@ts-ignore
const NorlysErrorPage = ({ order, setOrder, address, step }: INorlysErrorPage) => {
	const partnerContentContext = useContext(PartnerContentContext);
	const [errorTitle, setErrorTitle] = useState("");
	const [errorText, setErrorText] = useState("");
	const [errorImg, setErrorImg] = useState("");
	const [buttonText, setButtonText] = useState("");
	const [buttonLink, setButtonLink] = useState("");
	const [showCMU, setShowCMU] = useState(false);
	const [cmuReciept, setCmuReciept] = useState(false);
	const { errorType } = useParams<{ errorType: string }>();
	const DOMPurify = require("dompurify")(window);

	useEffect(() => {
		if (errorType === "faq") {
			loadFaq();
		} else if (errorType === "existing-customer") {
			loadExistingCustomer();
		} else if (errorType === "ring-mig-op-success") {
			loadCallMeUpSuccess();
		} 
		else if (errorType == "invalid-meteringpoint"){
			loadFaq();
		}
		else {
			loadGeneralError();
		}
	});

	const loadFaq = () => {
		if (step === 3 || step === 4) {
			setErrorTitle(partnerContentContext.faq.title);
			setErrorText(partnerContentContext.faq.text);
			setErrorImg(partnerContentContext.faq.image);
			setButtonText(partnerContentContext.faq.buttonText);
			setButtonLink(partnerContentContext.faq.buttonLink);
			setShowCMU(true);
		} else {
			loadGeneralError();
		}
	};

	const loadExistingCustomer = () => {
		setErrorTitle(partnerContentContext.excistingCostumer.title);
		setErrorText(partnerContentContext.excistingCostumer.text);
		setErrorImg(partnerContentContext.excistingCostumer.image);
		setButtonText(partnerContentContext.excistingCostumer.buttonText);
		setButtonLink(partnerContentContext.excistingCostumer.buttonLink);
	};

	const loadCallMeUpSuccess = () => {
		setErrorTitle(partnerContentContext.callMeUpReciept?.title);
		setErrorText(partnerContentContext.callMeUpReciept?.text);
		setCmuReciept(true);
		setErrorImg("");
		setButtonText("Afslut");
		setButtonLink("");
	};

	const loadGeneralError = () => {
		setErrorTitle("Error");
		setErrorText("Hov! Der skete en fejl...");
	};

	return (
		<div className="background-error">
			{!showCMU && (
				<ErrorStep
					errorTitle={errorTitle}
					errorText={errorText}
					buttonText={buttonText}
					buttonLink={buttonLink}
					errorImg={errorImg}
					cmuReciept={cmuReciept}></ErrorStep>
			)}
			{showCMU && (
				<div className="container">
					<h1 className="headerText">{errorTitle}</h1>
					<div
						className="bullet-list"
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(errorText) }}></div>
					<CallMeUpComponent
						order={order}
						address={address}
						showSecondButton={true}
						idqError={false}
						step={step}
					/>
				</div>
			)}
		</div>
	);
};
export default NorlysErrorPage;
