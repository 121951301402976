import "./App.scss";
import { StepComponent } from "./Components/LandingpageComponents/Wizard/StepComponent/StepComponent";
import ErrorBoundary from "./Components/SharedComponents/ErrorBoundary/ErrorBoundary";
import Header from "./Components/SharedComponents/Header/header";
import HeroBanner from "./Components/LandingpageComponents/HeroBanner/Herobanner";
import { useEffect, useState } from "react";
import NotFound from "./Components/SharedComponents/NotFound/NotFound";
import TokenContext from "./Contexts/TokenContext";
import PartnerContentContext from "./Contexts/PartnerContentContext";
import { PartnerContent } from "./Models/PartnerContentModel";
import { getPartnerContentObject } from "./Services/ApiService";
import Overlay from "./Components/LandingpageComponents/Wizard/Overlay/overlay";
import OverlayModel from "./Models/OverlayModel";
import { Route, Switch, useHistory } from "react-router-dom";
import NorlysErrorPage from "./Components/SharedComponents/ErrorPage/NorlysErrorPage";
import Redeempage from "./Components/RedeempageComponents/Redeempage/Redeempage";
import { OrderModel } from "./Models/OrderModel";
import AddressModel from "./Models/AddressModel";
import { StepEnum } from "./Models/StepEnum";
import { ThemeProvider } from "@mui/material";
import theme from "./Themes/theme";

function App() {
	const [bannerHidden, setBannerHidden] = useState(false);
	const [validPartner, setValidPartner] = useState(false);
	const [token, setToken] = useState("");
	const [partnerContent, setPartnerContent] = useState(new PartnerContent());
	const [overlayObject, setOverlayObject] = useState<OverlayModel>(
		new OverlayModel(true, "", true)
	);
	const [order, setOrder] = useState<OrderModel>(new OrderModel());
	const [address, setAddress] = useState<AddressModel>(new AddressModel());
	const [step, setStep] = useState(StepEnum.NameAddressStep);
	let history = useHistory();

	const fixPlaceholdersInText = (res: PartnerContent) => {
		const voucherValueNewSale = res.voucherValueNewSale;
		const voucherValueResale = res.voucherValueResale;
		const outboundSla = res.outboundSla;

		let json = JSON.stringify(res)
			.replaceAll("{voucherValueNewSale}", voucherValueNewSale?.toString())
			.replaceAll("{voucherValueResale}", voucherValueResale?.toString())
			.replaceAll("{outboundSla}", outboundSla?.toString());
		return JSON.parse(json) as PartnerContent;
	};

	const checkCleanPartnerName = (input: string) => {
		return input.replaceAll("%20", " ");
	};

	useEffect(() => {
		var pathUrl = window.location.pathname.substr(1);
		var splitstring = pathUrl.split("/", 1);
		var partner = splitstring[0];
		if (partner) {
			setOverlayObject(new OverlayModel(true, "", true));
			getPartnerContentObject(partner).then((res) => {
				if (res.isValid) {
					if (checkCleanPartnerName(partner) !== res.partnerId) {
						partner = res.partnerId;
						splitstring[1]
							? history.push(`/${res.partnerId}/${splitstring[1]}`)
							: history.push(`/${res.partnerId}`);
					}
					setPartnerContent(fixPlaceholdersInText(res));
					setValidPartner(true);
				} else {
					setValidPartner(false);
				}
				setOverlayObject(new OverlayModel(false, "", false));
			});
		} else {
			setValidPartner(false);
			setOverlayObject(new OverlayModel(false, "", false));
		}
		window.addEventListener("googleRecaptcha", (e) => {
			let event = e as CustomEvent;
			if (event && event.detail) {
				setToken(event.detail);
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ThemeProvider theme={theme}>
			<div>
				<Overlay
					active={overlayObject.overlayActive}
					message={overlayObject.overlayMessage}
					showLoader={overlayObject.overlayLoader}></Overlay>
				{!overlayObject.overlayActive && validPartner && (
					<div className="app-container">
						<ErrorBoundary>
							<PartnerContentContext.Provider value={partnerContent}>
								<Header></Header>
								<Switch>
									<Route path={`/${partnerContent.partnerId}`} exact>
										{!bannerHidden && <HeroBanner></HeroBanner>}
										<TokenContext.Provider value={token}>
											<StepComponent
												hideBanner={setBannerHidden}
												order={order}
												setOrder={setOrder}
												address={address}
												setAddress={setAddress}
												step={step}
												setStep={setStep}></StepComponent>
										</TokenContext.Provider>
									</Route>
									<Route path={`/${partnerContent.partnerId}/:errorType`} exact>
										<NorlysErrorPage
											order={order}
											setOrder={setOrder}
											address={address}
											step={step}></NorlysErrorPage>
									</Route>
									<Route
										path={`/${partnerContent.partnerId}/redeem/:${partnerContent.id}`}
										exact
										component={Redeempage}
									/>
									<Route component={NotFound} />
								</Switch>
							</PartnerContentContext.Provider>
						</ErrorBoundary>
					</div>
				)}
				{!overlayObject.overlayActive && !validPartner && <NotFound></NotFound>}
			</div>
		</ThemeProvider>
	);
}

export default App;
