export const Colors = {
  redStrong: "#F8232D",
  blueStrong: "#0A77ED",
  greenStrong: "#25D287",
  purpleStrong: "#755FFA",
  brownStrong: "#694F38",
  yellowStrong: "#FFDB38",
  turquiseStrong: "#369993",
  redBright: "#FFDEE5",
  blueBright: "#D4EEFA",
  greenBright: "#D3F4E8",
  purpleBright: "#E3E1FF",
  brownBright: "#EEE7E2",
  greenDark: "#103C23",
  blueDark: "#002B6B",
  grey100: "#323232",
  grey70: "#707070",
  grey40: "#B5B6B8",
  grey30: "#D5D6D8",
  grey10: "#E5E6E8",
  grey07: "#F0F0F2",
  grey05: "#F5F6F8",
  grey02: "#FAFBFD",
  white: "#FFFFFF",
};
