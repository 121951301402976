import { useState } from "react";
import {
	Accordion,
	AccordionMinimumHeight,
	ButtonColorEnum,
	InputComponent,
	ValidationTypeEnum,
} from "norlys-ui-components";
import Column from "../../Utils/Column/Column";

interface IInputStepInterface {
	checkVoucherValidation(input: string): void;
	toggleOpenState(): void;
	open: boolean;
	loading: boolean;
}

const InputStep = ({
	checkVoucherValidation,
	toggleOpenState,
	open,
	loading,
}: IInputStepInterface) => {
	const [input, setInput] = useState("");

	return (
		<div className="input-step">
			<Accordion
				loading={loading}
				loadingText="Tjekker rabatkode"
				loadingTitle="Vent venligst"
				label="Indtast rabatkode"
				open={open}
				click={toggleOpenState}
				secondButtonLabel={"VALIDER KODE"}
				secondButtonType={ButtonColorEnum.DARK_GREEN}
				secondButton={() => checkVoucherValidation(input)}
				minimumHeight={AccordionMinimumHeight.EXTRASMALL}
				secondButtonId="checkVoucherValidation"
				secondButtonShadow={true}
				secondButtonDisabled={input.length === 0}>
				<Column fullWidth={true}>
					<InputComponent
						placeholder="Rabatkode"
						validationType={ValidationTypeEnum.NOVALIDATION}
						change={(e): any => setInput(e.value)}
					/>
				</Column>
			</Accordion>
		</div>
	);
};

export default InputStep;
