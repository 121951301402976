import { IValidationResult } from "norlys-ui-components/lib/components/address-typeahead/Address-Typeahead";

class KvhxKode {
	husnummer: string = "";
	kommuneKode: string = "";
	sal: string = "";
	side: string = "";
	vejkode: string = "";
	constructor(
		husnummer: string = "",
		kommuneKode: string = "",
		sal: string = "",
		side: string = "",
		cpr: string = "",
		vejkode: string = ""
	) {
		this.husnummer = husnummer ?? "";
		this.kommuneKode = kommuneKode ?? "";
		this.sal = sal ?? "";
		this.side = side ?? "";
		this.vejkode = vejkode ?? "";
	}
}
class Postnr {
	postDistrikt: string = "";
	postnr: string = "";
	constructor(postDistrikt: string = "", postnr: string = "") {
		this.postDistrikt = postDistrikt ?? "";
		this.postnr = postnr ?? "";
	}
}
class AddressModel {
	kvhxKode!: KvhxKode;
	postnr!: Postnr;
	supplerendebynavn!: string;
	vejnavn!: string;
}
export const DAWAToDatahubAddress = (obj: IValidationResult): AddressModel => {
	let address = new AddressModel();
	address.kvhxKode = new KvhxKode(
		obj.value.adresse.husnr,
		"",
		obj.value.adresse.etage,
		obj.value.adresse.dør
	);
	address.postnr = new Postnr(obj.value.adresse.postnrnavn, obj.value.adresse.postnr);
	address.vejnavn = obj.value.adresse.vejnavn ?? "";
	address.supplerendebynavn = obj.value.adresse.supplerendebynavn ?? "";
	return address;
};

export class AddressRequest {
	street: string = "";
	houseNumber: string = "";
	floor: string = "";
	door: string = "";
	postCode: string = "";
	city: string = "";
}

export default AddressModel;
