import styled from 'styled-components'

interface IMobileMargin {
    marginBottomViewheight?: number;
    marginTopViewheight?: number;
}

const MobileMarginWrapper = styled.div<IMobileMargin>`
    display: ${window.innerWidth <= 768 ? 'block' : 'none'};
    transition: 0.3s all;
    margin-top: ${props => props.marginTopViewheight ? props.marginTopViewheight : 0}vh;
    margin-bottom: ${props => props.marginBottomViewheight ? props.marginBottomViewheight : 0}vh;

`;

export const MobileWrapper = ({ marginBottomViewheight, marginTopViewheight}: IMobileMargin) => {
    return (

        <MobileMarginWrapper marginBottomViewheight={marginBottomViewheight} marginTopViewheight={marginTopViewheight}></MobileMarginWrapper>
    )
}