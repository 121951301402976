export enum ProductCategory {
	El = "El",
	Emobility = "Emobility",
}

export enum ProductSubcategory {
	FLEX = "FLEX",
	KOMBI = "KOMBI",
	PULJE = "PULJE",
	EJE = "EJE",
	LEJE = "LEJE",
}
