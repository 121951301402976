import { Accordion, AccordionMinimumHeight, ButtonColorEnum, Checkbox } from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import "./SummaryStep.scss";
import { makeOrder } from "../../../../../Models/OrderRequest";
import { submitOrder } from "../../../../../Services/ApiService";
import { useContext, useState } from "react";
import { DashedLine } from "../../../../Utils/DashedLine/DashedLine";
import TokenContext from "../../../../../Contexts/TokenContext";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { Redirect } from "react-router-dom";
import { UrlHelper } from "../../../../../Helpers/UrlHelper";
import { PriceDetail } from "../../../../Utils/UI/PriceDetails/PriceDetail";
import { ProductSubcategory } from "../../../../../Models/Enums/ProductCategoryEnum";

const SummaryStep = ({
	previousStep,
	order,
	open,
	toggleOpen,
	setOrder,
	completeOrder,
	setOrderResult,
}: IStepInterface) => {
	const [loading, setLoading] = useState({
		loading: false,
		loadingTitle: "",
		loadingText: "",
	});
	const partnerContentContext = useContext(PartnerContentContext);
	const tokenContext = useContext(TokenContext);
	const companyTermsText = `Ja tak. Jeg giver samtykke til, at Norlys koncernens fem selskaber* må sende mig markedsføring via mail, sms og telefon inden for Norlys' produktkategorier** samt indsamle data om min interaktion, når jeg åbner markedsføring via mails og sms.</br></br> Jeg kan altid afmelde mig <a href="https://afmeld.norlys.dk/?_gl=1*148kfif*_ga*MTQxODA0Mjg0NS4xNjY5ODkwMzY5*_ga_21LE3BG3D3*MTY3NTM1Njc1NC40NS4wLjE2NzUzNTY3NTQuNjAuMC4w*_fplc*dGhhcUdMTkxVM0p6RGsxT0ZGWHZjYTRsYSUyRkpwTjNnMWlSeVY4RyUyQmtMem1mJTJCTjRoSUo5NHRjbW52bW1WTmo0MjV1QnElMkZKJTJCWUNQcElCV0laTHVUVWt2Uk54Q0d5ZnZuUk5CNG81RnhXUVF6N3UzS3dFQVlvMGFqSiUyQmtGWTl3JTNEJTNE" target="_blank" rel='noreferrer'>her</a>. Læs, hvordan vi behandler dine personlige data i vores <a href="https://norlys.dk/persondatapolitik/" target="_blank" rel="noreferrer">Persondatapolitik</a>. </br></br>
<i>*Norlys Energi A/S, Norlys Digital A/S, Norlys Erhverv A/S </br></br>**Internet, underholdningstjenester, telefoni, energi, opladning og det intelligente hjem.</i>`;

	const formValid = (): boolean => {
		return (
			!!order.fullName &&
			!!order.deliveryAddress &&
			!!order.cpr &&
			!!order.phone &&
			!!order.email &&
			order.acceptedTerms &&
			tokenContext.length > 0 &&
			order.IDQValid &&
			(order.DatahubValid || order.startDate !== undefined || order.CohabDatahubValid)
		);
	};

	const showHiddenCprNumber = (cpr: string): string => {
		if (cpr) {
			let result = `${cpr.substr(0, 6)}-xxxx`;
			return result;
		}
		return "";
	};

	const handleSubmitOrder = async () => {
		setLoading({
			...loading,
			loading: true,
			loadingText: "Din ordre behandles",
			loadingTitle: "Vent venligst...",
		});

		let orderDto = makeOrder(
			order,
			partnerContentContext.partnerId,
			order.storeChainInstallationNumber !== undefined
				? order.storeChainInstallationNumber.toString()
				: partnerContentContext.partnerInstallationNumber,
			partnerContentContext.estimatedYearlyUsage
		);

		let result = await submitOrder(tokenContext, orderDto);

		if (result.isSuccessful) {
			if (completeOrder) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "eec.SaleCompleted",
					eventCategory: "Ecommerce",
					eventAction: "Transactions",
					eventLabel: "Order_Partner_" + partnerContentContext.partnerId,
				});

				if (result.data) {
					if (setOrderResult) {
						setOrderResult(result.data);
					}
				}
				completeOrder();
			}
		} else {
			<Redirect push to={`/${partnerContentContext.partnerId}/faq`} />;
		}

		setLoading({
			...loading,
			loading: false,
			loadingText: "",
			loadingTitle: "",
		});
	};

	function isEastInstallation(): boolean {
		return parseInt(order.addressRequest.postCode) < 5000;
	}

	function getTermsInfoText(termsInfoFromConfig: string): string {
		let termsInfo = "";

		const electricityTerms = `<a href="https://norlys.dk/media/2ljiq4t1/da-el-privat-leveringsbetingelser-011223.pdf" rel="noreferrer" target="_blank">Elaftalen</a>`;
		const emobilityRentalTerms = `<a href="https://norlys.dk/media/mzxjs2ct/da-emobility-privat-leveringsbetingelser-leje-nov2023.pdf" rel="noreferrer" target="_blank">Ladeboks leje aftalen</a>`;
		const emobilityOwnershipTerms = `<a href="https://norlys.dk/media/wjpdu43w/da-emobility-privat-leveringsbetingelser-ejer-nov2023.pdf" rel="noreferrer" target="_blank">Ladeboks eje aftalen</a>`;

		if (order.chosenProducts.electricityProduct)
			termsInfo += termsInfo.length > 0 ? ` og ${electricityTerms}` : electricityTerms;
		if (order.chosenProducts.chargingstandProduct) {
			if (order.chosenProducts.chargingstandProduct.subcategory === ProductSubcategory.EJE)
				termsInfo +=
					termsInfo.length > 0 ? ` og ${emobilityOwnershipTerms}` : emobilityOwnershipTerms;
			else termsInfo += termsInfo.length > 0 ? ` og ${emobilityRentalTerms}` : emobilityRentalTerms;
		}
		return `${termsInfo + " " + termsInfoFromConfig}`;
	}

	return (
		<Accordion
			loading={loading.loading}
			loadingText={loading.loadingText}
			loadingTitle={loading.loadingTitle}
			label={partnerContentContext.summaryStep.title}
			open={open}
			click={toggleOpen}
			firstButtonLabel={
				partnerContentContext.summaryStep.backButton
					? partnerContentContext.summaryStep.backButton
					: "Tilbage"
			}
			firstButtonOutline={true}
			firstButton={previousStep}
			secondButtonLabel={
				partnerContentContext.summaryStep.submitOrderButton
					? partnerContentContext.summaryStep.submitOrderButton
					: "Bestil ordre"
			}
			secondButtonDisabled={!formValid()}
			secondButtonType={formValid() ? ButtonColorEnum.DARK_GREEN : ButtonColorEnum.GREY}
			secondButton={handleSubmitOrder}
			minimumHeight={AccordionMinimumHeight.SMALL}
			firstButtonId="SummaryStepReturn"
			secondButtonId="SummaryStepForward"
			secondButtonShadow={true}>
			<div className="summary-content">
				<div className="summary-container">
					<p id="subTitle">{partnerContentContext.summaryStep.subTitle}</p>
					{order.chosenProducts.electricityProduct && (
						<PriceDetail
							price={
								isEastInstallation()
									? order.chosenProducts.electricityProduct.mainPrice
									: order.chosenProducts.electricityProduct.secondaryPrice
							}
							unit={order.chosenProducts.electricityProduct.unit}
							renderInfoText={false}
							header={order.chosenProducts.electricityProduct.title}
							subcategory={order.chosenProducts.electricityProduct.subcategory}
							category={order.chosenProducts.electricityProduct.category}
							discounts={{
								installationDiscount: false,
								subscriptionDiscount: false,
								substitutionDiscount: false,
							}}
							substitution={false}
							emobilityInstallationDiscount={
								order.chosenProducts.electricityProduct.emobilityInstallationDiscount
							}
							emobilitySubscriptionDiscount={
								order.chosenProducts.electricityProduct.emobilitySubscriptionDiscount
							}
							emobilitySubstitutionDiscount={
								order.chosenProducts.electricityProduct.emobilitySubstitutionDiscount
							}
						/>
					)}
					{order.chosenProducts.chargingstandProduct && (
						<>
							<DashedLine />
							<PriceDetail
								price={order.chosenProducts.chargingstandProduct.mainPrice}
								unit={order.chosenProducts.chargingstandProduct.unit}
								renderInfoText={false}
								header={order.chosenProducts.chargingstandProduct.title}
								subcategory={order.chosenProducts.chargingstandProduct.subcategory}
								category={order.chosenProducts.chargingstandProduct.category}
								discounts={{
									installationDiscount: partnerContentContext.emobilityInstallationDiscountActive,
									subscriptionDiscount: partnerContentContext.emobilitySubscriptionDiscountActive,
									substitutionDiscount: partnerContentContext.emobilitySubstitutionDiscountActive,
								}}
								substitution={order.emobilityRemovalOfChargingStand ?? false}
								emobilityInstallationDiscount={
									order.chosenProducts.chargingstandProduct.emobilityInstallationDiscount
								}
								emobilitySubscriptionDiscount={
									order.chosenProducts.chargingstandProduct.emobilitySubscriptionDiscount
								}
								emobilitySubstitutionDiscount={
									order.chosenProducts.chargingstandProduct.emobilitySubstitutionDiscount
								}
							/>
						</>
					)}
					<DashedLine marginTop="30" marginBottom="10" />
					<div className="summary-row">
						<div className="summary-column">
							<div>
								<div>For- og efternavn</div>
								<div className="bold">{order.fullName}</div>
							</div>
						</div>
						<div className="summary-column cpr-desktop">
							<div>
								<div>CPR-nummer</div>
								<div className="bold">{showHiddenCprNumber(order.cpr)}</div>
							</div>
						</div>
					</div>
					{order.cohabFullName !== "" && (
						<div className="summary-row">
							<div className="summary-column">
								<div>
									<div>Samlevers For- og efternavn</div>
									<div className="bold">{order.cohabFullName}</div>
								</div>
							</div>
							<div className="summary-column cpr-desktop">
								<div>
									<div>Samlevers CPR-nummer</div>
									<div className="bold">{showHiddenCprNumber(order.cohabCpr)}</div>
								</div>
							</div>
						</div>
					)}
					<div className="summary-row">
						<div className="summary-column">
							<div>
								<div>E-mail</div>
								<div className="bold">{order.email}</div>
							</div>
						</div>
						<div className="summary-column">
							<div>
								<div>Telefonnummer</div>
								<div className="bold">{order.phone}</div>
							</div>
						</div>
					</div>
					<div className="summary-row">
						<div className="summary-column">
							<div>
								<div>{order.emobilitySelected ? "Installationsadresse" : "Leveringsadresse"}</div>
								<div className="bold">{order.deliveryAddress}</div>
							</div>
						</div>
						{order.separateDeliveryAddress && (
							<div className="summary-column">
								<div>
									<div>Alternativ leveringsadresse</div>
									<div className="bold">{order.separateDeliveryAddressString}</div>
								</div>
							</div>
						)}
					</div>
					{order.billingAddress && (
						<div className="summary-row">
							<div className="summary-column">
								<div>
									<div>Faktureringsadresse</div>
									<div className="bold">{order.billingAddress}</div>
								</div>
							</div>
							<div className="summary-column"></div>
						</div>
					)}
					{UrlHelper.urlContains("salesFlow") && (
						<div className="summary-row">
							<div className="summary-column">
								<div>
									<div>Sælger ID</div>
									<div className="bold">{order.salesPersonId}</div>
								</div>
							</div>
							<div className="summary-column">
								<div>
									<div>Butiksnummer</div>
									<div className="bold">{order.shopId}</div>
								</div>
							</div>
						</div>
					)}
					<div className="summary-row cpr-mobile">
						<div className="summary-column">
							<div>
								<div>CPR-nummer</div>
								<div className="bold">{showHiddenCprNumber(order.cpr)}</div>
							</div>
						</div>
					</div>
					{order.cohabCpr !== "" && (
						<div className="summary-row cpr-mobile">
							<div className="summary-column">
								<div>
									<div>Samlevers CPR-nummer</div>
									<div className="bold">{showHiddenCprNumber(order.cohabCpr)}</div>
								</div>
							</div>
						</div>
					)}
					{order.startDate && (
						<div className="summary-row">
							<div className="summary-column">
								<div>
									<div>Indflytningsdato</div>
									<div className="bold">{order.startDate.toLocaleDateString()}</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<DashedLine marginTop="30" marginBottom="10" />
				<div className="privacy-policy">
					<p className="bold">Vi passer godt på dine data</p>
					<div>
						Du kan læse hvordan vi behandler dine data i vores{" "}
						<a href="https://norlys.dk/persondatapolitik" target="blank">
							persondatapolitik
						</a>
					</div>
				</div>
				<DashedLine marginTop="30" marginBottom="20" />
				<div className="accept-marketing">
					<Checkbox
						change={(): any => {
							setOrder({ ...order, newsletterSignup: !order.newsletterSignup });
						}}
						label={companyTermsText}></Checkbox>
				</div>
				<DashedLine marginTop="20" marginBottom="20"></DashedLine>
				<div className="accept-terms">
					<Checkbox
						change={(): any => {
							setOrder({ ...order, acceptedTerms: !order.acceptedTerms });
						}}
						label={`Jeg har læst og accepteret betingelserne for 
						${getTermsInfoText(partnerContentContext.summaryStep.terms)}.`}
					/>
					<div className="terms-subtext">{partnerContentContext.summaryStep.termsInfo}</div>
				</div>
				<DashedLine marginTop="20" marginBottom="20"></DashedLine>
				<div className="token-container">
					<div className="g-recaptcha" id="recaptchaSummary"></div>
				</div>
			</div>
		</Accordion>
	);
};

export default SummaryStep;
