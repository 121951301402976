import { Badge, Tooltip, styled } from "@mui/material";

interface IInfoTooltip {
	message: string;
	placement:
		| "bottom"
		| "left"
		| "right"
		| "top"
		| "bottom-end"
		| "bottom-start"
		| "left-end"
		| "left-start"
		| "right-end"
		| "right-start"
		| "top-end"
		| "top-start"
		| undefined;
	/**Padding value in rem*/
	paddingRight?: number;
	/**Padding value in rem*/
	paddingLeft?: number;
}

export const InfoTooltip: React.FC<IInfoTooltip> = (props) => {
	return (
		<Tooltip
			arrow
			title={props.message}
			placement={props.placement}
			sx={{ ml: 2, mt: 3, cursor: "pointer" }}>
			<Badge
				sx={{
					"& .MuiBadge-badge": {
						pr: `${props.paddingRight ?? 0.313}rem`,
						pl: `${props.paddingLeft ?? 0.313}rem`,
					},
				}}
				badgeContent={"i"}
				color="info"
			/>
		</Tooltip>
	);
};
