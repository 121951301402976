import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	styled,
} from "@mui/material";

interface INorlysRadioButton {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	values: { label: string; value: boolean }[];
	id: string;
	title?: string;
	chosenValue: boolean;
	children?: React.ReactNode;
}

export const NorlysRadioButton: React.FC<INorlysRadioButton> = (props) => {
	return (
		<FormControl>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Typography variant="subtitle2" mt={3}>
					{props.title}
				</Typography>
				<Box sx={{ marginLeft: "1.25rem" }}>{props.children}</Box>
			</Box>
			<RadioGroup
				value={props.chosenValue}
				onChange={(event) => props.onChange(event)}
				sx={{ mt: 1 }}>
				{props.values.map((value, index) => {
					const uniqueKey = `radio_${index}`;
					return (
						<FormControlLabel
							key={uniqueKey}
							value={value.value as boolean}
							control={<StyledRadio id={props.id} />}
							label={value.label}
							sx={{ fontVariant: "body2", padding: "0.25rem 0" }}
						/>
					);
				})}
			</RadioGroup>
		</FormControl>
	);
};

const StyledRadio = styled(Radio)`
	& .MuiSvgIcon-root {
		font-size: 1.75rem;
	}
	&.Mui-checked {
		color: #25d287;
	}
`;
