import "./ErrorStep.scss";
import { Button, ButtonColorEnum } from "norlys-ui-components";
import IErrorStepInterface from "./../../../../../Interfaces/IErrorStepInterface";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { useContext } from "react";

const ErrorStep = ({
	errorTitle,
	errorText,
	errorImg,
	buttonText,
	buttonLink,
	cmuReciept,
}: IErrorStepInterface) => {
	const handleRouting = () => {
		window.history.back();
		window.location.replace(`/${partnerContentContext.partnerId}`);
	};

	const partnerContentContext = useContext(PartnerContentContext);
	const DOMPurify = require("dompurify")(window);

	return (
		<div className="error-container">
			<div className="text-container">
				<h1 className="error-title">{errorTitle}</h1>
				<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(errorText) }}></div>
				{buttonText && (
					<div>
						<Button
							label={buttonText}
							color={ButtonColorEnum.DARK_GREEN}
							onClick={cmuReciept ? () => handleRouting() : () => window.open(buttonLink)}
						/>
					</div>
				)}
			</div>

			<div className="image-container">
				<img
					src={
						errorImg.substr(0, 4) === "http"
							? errorImg
							: errorImg
							? require(`../../../../../Assets/Images/${errorImg}`)?.default
							: require(`../../../../../Assets/Images/hjælpeløs_farve.png`)?.default
					}
					alt="logo"
					className="partner-logo"
				/>
			</div>
		</div>
	);
};
export default ErrorStep;
