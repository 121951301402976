import "./header.scss";
import { DashedLine } from "../../Utils/DashedLine/DashedLine";
import { useContext } from "react";
import PartnerContentContext from "../../../Contexts/PartnerContentContext";

const Header = () => {
	const partnerContentContext = useContext(PartnerContentContext);

	return (
		<div className="app-header">
			<div className="image-section">
				<div className="image-box">
					<svg
						width="132"
						height="26"
						viewBox="0 0 132 26"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M17.3008 0.51178V15.8238L4.62476 0.51178H-0.000244141V24.6718H5.12176V9.17478L17.9548 24.6718H22.4438V0.51178H17.3008Z"
							fill="#F7232D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M106.866 0.51178L101.04 10.8778L95.2149 0.51178H89.4189L98.4469 16.1458V24.6718H103.634V16.1458L112.662 0.51178H106.866Z"
							fill="#F7232D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M77.4521 0.510925V24.6709H94.4711V19.6299H82.6081V0.510925H77.4521Z"
							fill="#F7232D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M70.1005 14.8186C72.2985 13.3496 73.6115 10.9096 73.6115 8.28959C73.6115 4.29359 70.5705 0.99259 66.6715 0.56059C66.4155 0.53959 65.9755 0.50859 65.5795 0.51059H53.3115V24.6716H58.4665V16.1616H63.5585L68.3875 24.6716H74.1825L68.9265 15.4186C69.6365 15.1356 70.0295 14.8696 70.1005 14.8186ZM65.7385 11.1326H58.4045V5.57159H65.4905C65.8715 5.58059 66.2615 5.60759 66.6805 5.74559C67.7325 6.14059 68.4875 7.17259 68.4875 8.38359C68.4875 9.89959 67.2535 11.1326 65.7385 11.1326Z"
							fill="#F7232D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M125.546 10.3269C124.361 10.0649 122.957 9.85491 121.719 9.66891C120.844 9.53791 120.018 9.41391 119.573 9.31491C117.771 8.91291 117.779 7.75991 117.784 7.25091C117.795 5.97991 119.252 5.22291 120.692 5.11291C120.934 5.08891 121.224 5.07291 121.57 5.07491C121.947 5.07691 122.255 5.09391 122.511 5.11791C124.396 5.23391 125.491 6.21191 125.806 6.95591C125.904 7.18991 125.951 7.33191 125.951 7.33191H130.978C130.983 5.48691 130.082 3.64391 128.502 2.26991C126.975 0.94191 124.924 0.14591 122.729 0.0299101C122.158 -8.98987e-05 121.382 -0.0070899 120.752 0.0129101C116.192 0.15391 112.729 3.24591 112.694 7.20691C112.664 10.7129 114.875 13.3879 118.467 14.1879C119.086 14.3259 119.956 14.4559 120.964 14.6079C122.121 14.7809 123.434 14.9769 124.447 15.2019C126.925 15.7489 126.913 17.0929 126.909 17.5349C126.896 19.2039 125.289 19.7619 124.1 19.9459C123.693 20.0259 123.076 20.1029 122.207 20.0979C121.305 20.0919 120.681 20.0419 120.254 19.9839C119.224 19.8739 118.296 19.4429 117.625 18.7849C117.21 18.3779 116.993 17.9649 116.841 17.3629H111.823C111.823 19.1679 112.592 20.9499 114.011 22.3689C115.941 24.2989 118.329 25.0259 120.836 25.0999C121.658 25.1239 121.966 25.1279 122.921 25.1219C125.319 25.1059 127.302 24.6509 129.258 23.1759C131.031 21.8379 131.981 19.8179 132 17.5779C132.031 13.9339 129.559 11.2149 125.546 10.3269Z"
							fill="#F7232D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M47.1845 2.9519C45.4545 1.2279 43.2515 0.215901 40.8785 0.0599006C39.9275 -0.00309939 39.1575 -9.9386e-05 39.1575 -9.9386e-05V5.2219C39.1575 5.2219 40.5805 5.2929 41.2965 5.4779C43.2735 5.9889 44.8265 7.8409 44.9385 10.0559C45.0665 12.5649 45.0745 12.8599 44.9405 15.0529C44.8035 17.2819 43.2495 19.1569 41.2835 19.6579C40.4555 19.8689 39.1575 19.9379 39.1575 19.9379V25.1359C39.1575 25.1359 40.2285 25.1199 40.8785 25.0769C43.2625 24.9129 45.4465 23.9039 47.1745 22.1719C48.9945 20.3509 50.0815 17.9379 50.2395 15.3769C50.3865 12.9669 50.3775 12.4809 50.2405 9.7849C50.1095 7.2109 49.0245 4.7839 47.1845 2.9519Z"
							fill="#F7232D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M34.873 0.0598853C32.5 0.215885 30.298 1.22789 28.567 2.95189C26.727 4.78389 25.643 7.21089 25.512 9.78588C25.374 12.4809 25.365 12.9669 25.513 15.3769C25.67 17.9379 26.758 20.3509 28.577 22.1719C30.306 23.9039 32.489 24.9139 34.873 25.0759C35.523 25.1209 36.595 25.1359 36.595 25.1359V19.9379C36.595 19.9379 35.297 19.8699 34.469 19.6589C32.502 19.1569 30.948 17.2819 30.811 15.0529C30.678 12.8599 30.686 12.5649 30.813 10.0559C30.926 7.84089 32.478 5.98889 34.455 5.47789C35.172 5.29289 36.595 5.22189 36.595 5.22189V-0.000114724C36.595 -0.000114724 35.824 -0.00211472 34.873 0.0598853Z"
							fill="#F7232D"
						/>
					</svg>
				</div>
				<div className="filler"></div>
				<div className="image-box">
					{partnerContentContext.header.name && partnerContentContext.header.name.length > 0 && (
						<div className="image-box">
							<img
								src={
									partnerContentContext.header.name.substr(0, 4) === "http"
										? partnerContentContext.header.name
										: require(`../../../Assets/Logos/${partnerContentContext.header.name}`)?.default
								}
								alt="logo"></img>
						</div>
					)}
				</div>
			</div>
			<DashedLine hiddenMobile={true} marginBottom="10" marginTop="20"></DashedLine>
		</div>
	);
};

export default Header;
