import { AddressTypeahead, InputComponent, ValidationTypeEnum } from "norlys-ui-components";
import "./EmobilityStep.scss";
import { OrderModel } from "../../../../../Models/OrderModel";
import { useContext, useEffect, useState } from "react";
import { IInputValidationResult } from "norlys-ui-components/lib/components/input-component/Input-Component";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormControl } from "@mui/material";
import { NorlysRadioButton } from "../../../../Utils/UI/RadioButton/NorlysRadioButton";
import { IValidationResult } from "norlys-ui-components/lib/components/address-typeahead/Address-Typeahead";
import { InfoTooltip } from "../../../../Utils/UI/InfoBadge/InfoBadge";

export interface IEmobilityStep {
	order: OrderModel;
	setOrder: React.Dispatch<React.SetStateAction<OrderModel>>;
}

export const EmobilityStep = ({ order, setOrder }: IEmobilityStep) => {
	const partnerContentContext = useContext(PartnerContentContext);
	const [months, setMonths] = useState<Array<Date>>([]);

	const danish = new Intl.Locale("da-DK");

	useEffect(() => {
		setMonths(generateMonths());
	}, []);

	function generateMonths(): Array<Date> {
		const nextTwelveMonths: Date[] = [];
		for (let i = 1; i < 13; i++) {
			let date = new Date();
			date.setDate(1);
			date.setMonth(date.getMonth() + i);
			nextTwelveMonths.push(date);
		}
		return nextTwelveMonths;
	}

	const handleCampaignCodeChange = (val: IInputValidationResult): any => {
		setOrder({
			...order,
			emobilityCampaign: val.value,
		});
	};

	const handleMonthChange = (event: SelectChangeEvent) => {
		let date = new Date(event.target.value);
		let datestring = getMonthstring(date);

		setOrder({
			...order,
			emobilityWishedInstallationMonthName: datestring,
			emobilityWishedInstallationMonth: date,
		});
	};

	const getMonthstring = (date: Date) => {
		return date.toLocaleString(danish, { month: "long" }) + " " + date.getFullYear();
	};

	function stringToBoolean(value: string): boolean {
		if (value.toLowerCase() === "true") return true;
		else return false;
	}

	const handleAddressChange = (val: IValidationResult): any => {
		if (val.valid) {
			setOrder({
				...order,
				separateDeliveryAddressString: val.value.tekst,
				separateDeliveryAddressId: val.value.adresse.id.toString(),
				separateDeliveryAddressRequest: {
					city: val.value.adresse.postnrnavn,
					door: val.value.adresse.dør,
					floor: val.value.adresse.etage,
					houseNumber: val.value.adresse.husnr,
					postCode: val.value.adresse.postnr,
					street: val.value.adresse.vejnavn,
				},
			});
		} else {
			setOrder({
				...order,
				separateDeliveryAddressRequest: undefined,
				separateDeliveryAddressId: "",
			});
		}
	};

	return (
		<div>
			<div className="emobility-wrapper">
				<div>
					<div className="month-select-title" style={{ fontWeight: "bold" }}>
						Ønsket installationsmåned
					</div>
					<FormControl fullWidth={true}>
						<Box width={600}>
							<Select
								fullWidth={true}
								onChange={handleMonthChange}
								sx={{ backgroundColor: "white" }}>
								{months.map((month) => {
									return (
										<MenuItem key={month.toString()} value={month.toString()}>
											{getMonthstring(month)}
										</MenuItem>
									);
								})}
							</Select>
						</Box>
					</FormControl>
					<Box sx={{ display: "flex", flexDirection: "column", marginLeft: "0.2rem" }}>
						<NorlysRadioButton
							id={"emobilityRemovalOfChargingStand"}
							title={"Har du allerede en ladeboks installeret på adressen?"}
							onChange={(event) => {
								setOrder({
									...order,
									emobilityRemovalOfChargingStand: stringToBoolean(event.target.value),
								});
							}}
							values={[
								{ label: "Nej", value: false },
								{ label: "Ja", value: true },
							]}
							chosenValue={order.emobilityRemovalOfChargingStand ?? false}>
							<InfoTooltip
								placement="top"
								paddingRight={0.406}
								message="Hvis du i forvejen har en ladeboks, kan du spare penge på installationen. Kablet fra din eltavle til ladeboksen skal have en minimumstykkelse på 5 &lowast; 4 mm&#178; (til 11kW ladeboks) og den må ikke forsyne andet end ladeboksen. For at få rabat på installationen, skal du have en elaftale hos Norlys."
							/>
						</NorlysRadioButton>

						<NorlysRadioButton
							id={"separateDeliveryAddress"}
							title={
								"Ladeboksen bliver sendt direkte til dig. Skal den leveres til en anden adresse end installationsadressen?"
							}
							onChange={(event) => {
								setOrder({
									...order,
									separateDeliveryAddress: stringToBoolean(event.target.value),
								});
							}}
							values={[
								{ label: "Nej", value: false },
								{ label: "Ja", value: true },
							]}
							chosenValue={order.separateDeliveryAddress ?? false}
						/>
						{order.separateDeliveryAddress && (
							<Box sx={{ marginTop: "1rem;" }}>
								<AddressTypeahead
									fuzzy={true}
									placeholder={"Alternativ leveringsadresse"}
									change={handleAddressChange}
								/>
							</Box>
						)}
						{partnerContentContext.financingEnabled && (
							<NorlysRadioButton
								id={"emobilityDealerFunded"}
								title={
									"Hvordan skal engangsudgiften for ladeboks og standard installation betales?"
								}
								onChange={(event) => {
									setOrder({
										...order,
										emobilityDealerFunded: stringToBoolean(event.target.value),
									});
								}}
								values={[
									{ label: "På min regning fra Norlys", value: false },
									{ label: "Afregnes til partner", value: true },
								]}
								chosenValue={order.emobilityDealerFunded ?? false}
							/>
						)}
						{partnerContentContext.campaignCodeEnabled && (
							<Box marginTop={"1rem"}>
								<InputComponent
									width={548}
									placeholder="Kampagnekode"
									validationType={ValidationTypeEnum.NOVALIDATION}
									change={(e): any => {
										handleCampaignCodeChange(e);
									}}
								/>
							</Box>
						)}
					</Box>
				</div>
			</div>
		</div>
	);
};
