class VoucherModel {
	code: string = "";
	issueDate: string = "";
	statusChangeDate: string = "";
	statusId: string = "";
	batchType: string = "";
	expirationMonths: number = 0;
	voucherValue: number = 0;
	customerName: string = "";
	orderId: string = "";
	id: number = 0;
	shopId: string = "";
	sellerId: string = "";
	isValid: boolean = false;
}

export default VoucherModel;
