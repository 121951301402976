import { getDatahubMatch, getIDQMatch, getSonwinMatch } from "../../Services/ApiService";

const ValidateInput = async (
	cpr: string,
	fullName: string,
	datahubAddress: any,
	deliveryAddressId: string,
	resolveValidation: any,
	cohabStep: boolean,
	company: string
) => {
	if (!cohabStep) {
		await Promise.allSettled([
			getIDQMatch(cpr, fullName),
			getDatahubMatch(fullName, datahubAddress),
			getSonwinMatch(deliveryAddressId, company),
		]).then((result) => {
			let iDQResult = result[0];
			let datahubResult = result[1];
			let sonwinResult = result[2];
			resolveValidation(iDQResult, datahubResult, sonwinResult);
		});
	} else {
		await Promise.allSettled([
			getIDQMatch(cpr, fullName),
			getDatahubMatch(fullName, datahubAddress),
		]).then((result) => {
			let iDQResult = result[0];
			let datahubResult = result[1];
			resolveValidation(iDQResult, datahubResult);
		});
	}
};
export { ValidateInput };
